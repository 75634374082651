import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  List,
  ListItem,
  NumberInput,
  NumberInputField,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";
import { useCombobox } from "downshift";
import { categorizedSkills } from "./../DataSets/skills";

// Flatten the categorized skills into a single array
const allSkills = Object.values(categorizedSkills).flat();

const SkillsInput = ({ skills, setSkills }) => {
  const [inputValue, setInputValue] = useState("");
  const [experience, setExperience] = useState(0);
  const skillInputRef = useRef(null);
  const experienceInputRef = useRef(null);
  const addButtonRef = useRef(null);

  const [editingSkill, setEditingSkill] = useState(null); // Track the skill being edited
  const [editedExperience, setEditedExperience] = useState("");

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
    setHighlightedIndex,
  } = useCombobox({
    items: allSkills.filter((skill) =>
      skill.toLowerCase().includes(inputValue.toLowerCase())
    ),
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setInputValue(selectedItem);
        setTimeout(() => {
          experienceInputRef.current?.focus();
        }, 0);
      }
    },
  });

  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      experienceInputRef.current === document.activeElement
    ) {
      addSkill();
    }
  };

  const addSkill = () => {
    if (inputValue && !skills.some((skill) => skill.skillName === inputValue)) {
      setSkills([
        ...skills,
        { skillName: inputValue, relevantExp: experience },
      ]);
      setInputValue("");
      setExperience(0);
      setTimeout(() => {
        skillInputRef.current?.focus();
        setHighlightedIndex(0);
        addButtonRef.current?.blur();
      }, 0);
    }
  };

  const saveEditedSkill = () => {
    setSkills(
      skills.map((skill) =>
        skill.skillName === editingSkill.skillName
          ? { ...skill, relevantExp: parseInt(editedExperience) || 0 }
          : skill
      )
    );
    setEditingSkill(null); // Exit edit mode
    setEditedExperience("");
  };

  const removeSkill = (skillToRemove) => {
    setSkills(skills.filter((skill) => skill.skillName !== skillToRemove));
  };

  useEffect(() => {
    if (experienceInputRef.current) {
      experienceInputRef.current.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      if (experienceInputRef.current) {
        experienceInputRef.current.removeEventListener(
          "keydown",
          handleKeyDown
        );
      }
    };
  }, [experienceInputRef.current, handleKeyDown]);

  console.log(skills)

  return (
    <FormControl id="skills">
      <FormLabel mb={0}>Skills</FormLabel>
      <Box>
        <Input
          {...getInputProps({
            placeholder: "Type a skill, Ex. HTML",
            ref: skillInputRef,
            value: inputValue,
          })}
        />
        <List
          {...getMenuProps()}
          borderRadius="md"
          maxH="150px"
          overflowY="auto"
        >
          {isOpen &&
            allSkills
              .filter((skill) =>
                skill.toLowerCase().includes(inputValue.toLowerCase())
              )
              .map((item, index) => (
                <ListItem
                  key={index}
                  {...getItemProps({ item, index })}
                  bg={highlightedIndex === index ? "#ffffff29" : "#ffffff14"}
                  p={2}
                  cursor="pointer"
                >
                  {item}
                </ListItem>
              ))}
        </List>
      </Box>
      {inputValue && (
        <Box mt={2} display="flex" alignItems="center">
          <FormLabel htmlFor="experience" mb="0">
            Years of Experience:
          </FormLabel>
          <NumberInput
            id="experience"
            value={experience}
            onChange={(valueString) => {
              const parsedValue = parseInt(valueString);
              setExperience(isNaN(parsedValue) ? 0 : parsedValue);
            }}
            min={0}
            max={50}
            mr={2}
            placeholder="Ex. 3"
          >
            <NumberInputField ref={experienceInputRef} />
          </NumberInput>
          <Button ref={addButtonRef} onClick={addSkill} colorScheme="teal">
            Add Skill
          </Button>
        </Box>
      )}
      {skills.length > 0 && (
        <Box mt={2}>
          {skills.map((skill, index) => (
            <Tag
              key={index}
              size="md"
              borderRadius="full"
              variant="solid"
              fontSize={"sm"}
              colorScheme="teal"
              mt={1}
              mx={1}
            >
              <TagLabel pl={2}>{skill.skillName}</TagLabel>
              {editingSkill?.skillName === skill.skillName ? (
                <>
                  <Input
                    value={editedExperience}
                    onChange={(e) => setEditedExperience(e.target.value)}
                    size="sm"
                    width="40px"
                    mx={2}
                  />
                  <IconButton
                    size="sm"
                    colorScheme="teal"
                    icon={<CheckIcon />}
                    onClick={saveEditedSkill}
                  />
                </>
              ) : (
                <>
                  <TagLabel ml={2}>{`${skill.relevantExp} years`}</TagLabel>
                  <IconButton
                    size="sm"
                    colorScheme="gray"
                    icon={<EditIcon />}
                    onClick={() => {
                      setEditingSkill(skill);
                      setEditedExperience(skill.relevantExp.toString());
                    }}
                    ml={2}
                  />
                </>
              )}
              <TagCloseButton onClick={() => removeSkill(skill.skillName)} />
            </Tag>
          ))}
        </Box>
      )}
    </FormControl>
  );
};

export default SkillsInput;
