import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import JdContainer from "./JobDescription/JdContainer";
import CandidateContainer from "./Candidate/CandidateContainer";
import ApplicationContainer from "./Application/ApplicationContainer";
import CVProcessContainer from "./CVProcess/CVProcessContainer";
import InterviewProcessContainer from "./InterviewProcess/InterviewProcessContainer";
import OfferProcessContainer from "./OfferProcess/OfferProcessContainer";
import PlacementProcessContainer from "./PlacementProcess/PlacementProcessContainer";
import BillingContainer from "./Billings/BillingContainer";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { TfiDashboard } from "react-icons/tfi";
import Dashboard from "./DashboardTools/Dashboard";
import { useAuth } from "../../context/AuthContext";

const RecruiterBody = () => {
  const { userData } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const { tab } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const initialPath = pathname.split("/")[1];
//   console.log(userData);
  useEffect(() => {
    switch (tab) {
      case "Dashboard":
        setTabIndex(0);
        break;
      case "JobDescriptions":
        setTabIndex(1);
        break;
      case "Candidates":
        setTabIndex(2);
        break;
      case "Applications":
        setTabIndex(3);
        break;
      case "CVProcess":
        setTabIndex(4);
        break;
      case "InterviewProcess":
        setTabIndex(5);
        break;
      case "OfferProcess":
        setTabIndex(6);
        break;
      case "placementProcess":
        setTabIndex(7);
        break;
      case "Billing":
        setTabIndex(8);
        break;
      default:
        // Redirect or handle the default case
        setTabIndex(0); // Optional default fallback
        break;
    }
  }, [tab]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    const tabMapping = [
      "Dashboard",
      "JobDescriptions",
      "Candidates",
      "Applications",
      "CVProcess",
      "InterviewProcess",
      "OfferProcess",
      "placementProcess",
      "Billing",
    ];
    navigate(`/${initialPath}/${tabMapping[index]}/all`);
  };

  return (
    <div
      className="recruiter-body"
      style={{
        background: "linear-gradient(180deg, black, rgb(29, 48, 75))",
        height: "calc(100vh - 3rem)",
        overflow: "clip",
      }}
    >
      <Tabs
        isLazy
        variant="enclosed"
        colorScheme="green"
        color={"white"}
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList mb="1" style={{ background: "black" }}>
          <Tab>
            <Icon as={TfiDashboard} fontSize={"24px"} />
          </Tab>
          <Tab>Job Descriptions</Tab>
          <Tab>Candidates</Tab>
          <Tab>Applications</Tab>
          <Tab>CV Process</Tab>
          <Tab>Interview Process</Tab>
          <Tab>Offer Process</Tab>
          <Tab>Placement Process</Tab>
          {userData?.canViewBilling && <Tab>Billing</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel
            pb={0}
            style={{
              height: "calc(100vh - 5rem)",
              overflow: "clip",
              paddingTop: 0,
            }}
            pl={0}
            pr={0}
          >
            <Dashboard />
          </TabPanel>
          <TabPanel
            pb={0}
            style={{
              height: "calc(100vh - 7rem)",
              overflow: "clip",
              paddingTop: 0,
            }}
          >
            <JdContainer />
          </TabPanel>
          <TabPanel
            pb={0}
            style={{
              height: "calc(100vh - 7rem)",
              overflow: "clip",
              paddingTop: 0,
            }}
          >
            <CandidateContainer />
          </TabPanel>
          <TabPanel
            pb={0}
            style={{
              height: "calc(100vh - 7rem)",
              overflow: "clip",
              paddingTop: 0,
            }}
          >
            <ApplicationContainer />
          </TabPanel>
          <TabPanel
            pb={0}
            style={{
              height: "calc(100vh - 7rem)",
              overflow: "clip",
              paddingTop: 0,
            }}
          >
            <CVProcessContainer />
          </TabPanel>
          <TabPanel
            pb={0}
            style={{
              height: "calc(100vh - 7rem)",
              overflow: "clip",
              paddingTop: 0,
            }}
          >
            <InterviewProcessContainer />
          </TabPanel>
          <TabPanel
            pb={0}
            style={{
              height: "calc(100vh - 7rem)",
              overflow: "clip",
              paddingTop: 0,
            }}
          >
            <OfferProcessContainer />
          </TabPanel>
          <TabPanel
            pb={0}
            style={{
              height: "calc(100vh - 7rem)",
              overflow: "clip",
              paddingTop: 0,
            }}
          >
            <PlacementProcessContainer />
          </TabPanel>
          <TabPanel
            pb={0}
            style={{
              height: "calc(100vh - 7rem)",
              overflow: "clip",
              paddingTop: 0,
            }}
          >
            <BillingContainer />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default RecruiterBody;
