import React from "react";
import NavBar from "../Components/NavBar/NavBar";
import ManagerBody from "../Components/Body/ManagerBody.js";

const ManagerPage = () => {
    return (
        <div>
            <NavBar />
            <ManagerBody />
        </div>
    );
};

export default ManagerPage;
