import { Fragment, useState } from "react";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from "@headlessui/react";
// import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import useGlobalToast from "../../Utils/GlobalFunctions/toast";
import { Avatar, Flex, MenuGroup, MenuList, Text } from "@chakra-ui/react";
import { useEnv } from "../../context/EnvContext";
import logo from "../../assets/logo.png";
import EmployeeDetails from "../Body/Employees/EmployeeDetails";
import { useUser } from "../../context/UserContext";
import TimeDisplay from "./Time";

const navigation = [{ name: "Dashboard", href: "#", current: false }];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function HomeNavBar() {
    const toast = useGlobalToast();
    const { backendUrl } = useEnv();
    const { userData, logout } = useAuth();
    const { loading, getMyProfile, myProfile } = useUser();
    const navigate = useNavigate();
    const [isViewMyProfileModalOpen, setIsViewMyProfileModalOpen] = useState(false);

    // Handle Employee Form modal Preview
    const handleViewMyProfile = () => {
        getMyProfile();
        setIsViewMyProfileModalOpen(true);
    };

    const handleViewMyProfileCloseModal = () => {
        setIsViewMyProfileModalOpen(false);
    };

    const handleRedirect = () => {
        if (userData?.userType) {
            const role = userData?.userType;

            switch (role) {
                case "Admin":
                    navigate("/admin_dashboard/Dashboard/dashboard");
                    break;
                case "HR Manager":
                    navigate("/hr_dashboard/Dashboard/dashboard");
                    break;
                case "Business Developer":
                    navigate("/bd_dashboard/Dashboard/Default");
                    break;
                case "Recruiter":
                    navigate("/recruiter_dashboard/Dashboard/Default");
                    break;
                default:
                    toast.error("Role not recognized!", "");
                    break;
            }
        }
    };
    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="mx-auto w-full px-1 sm:px-5">
                        <div className="relative flex h-12 items-center justify-between">
                            <div
                                className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
                                style={{ alignItems: "center" }}
                            >
                                <div className="flex flex-shrink-0 items-center">
                                    <img className="h-8 w-auto" src={logo} alt="ATS" />
                                </div>
                                <div className="hidden sm:ml-2 sm:block">
                                    <div className="flex space-x-4">
                                        <button
                                            className={classNames(
                                                "bg-gray-900 text-white",
                                                "rounded-md px-2 py-1 text-sm font-medium"
                                            )}
                                            style={{
                                                cursor: "default",
                                            }}
                                        >
                                            <TimeDisplay />
                                        </button>
                                    </div>
                                </div>
                                <div className="hidden sm:ml-2 sm:block">
                                    {userData && (
                                        <div className="flex space-x-4">
                                            <button
                                                onClick={() => {
                                                    handleRedirect();
                                                }}
                                                className={classNames(
                                                    "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                    "rounded-md px-2 py-1 text-sm font-medium"
                                                )}
                                            >
                                                My Dashboard
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {userData ? (
                                    <>
                                        <div className="hidden  sm:block">
                                            <Flex direction="column" align={"flex-end"}>
                                                <Text className="text-sm font-medium">
                                                    {userData?.empId}
                                                </Text>
                                                <Text className="text-xs font-medium">
                                                    {userData?.userType}
                                                    {" - "}
                                                    {userData?.name}
                                                </Text>
                                            </Flex>
                                        </div>
                                        <Menu as="div" className="relative ml-3">
                                            <div>
                                                <MenuButton className="relative flex rounded-full bg-gray-800 text-sm  hover:outline-none hover:ring-2 hover:ring-white hover:ring-offset-2 hover:ring-offset-gray-800">
                                                    <span className="absolute -inset-1.5" />
                                                    <Avatar
                                                        size="sm"
                                                        name={userData?.name}
                                                        src={`${backendUrl}${userData?.dp}`}
                                                    />
                                                </MenuButton>
                                            </div>
                                            <Transition
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <MenuItems className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <Text
                                                        mb={1}
                                                        color={"#374151"}
                                                        fontWeight={"bold"}
                                                    >
                                                        Hi, {userData?.empId}
                                                    </Text>
                                                    <hr
                                                        style={{
                                                            borderColor: "#374151",
                                                            borderStyle: "dashed",
                                                            margin: "auto",
                                                            width: "90%",
                                                        }}
                                                    />
                                                    <MenuItem>
                                                        {({ focus }) => (
                                                            <button
                                                                className={classNames(
                                                                    focus ? "bg-gray-100" : "",
                                                                    "block px-4 py-2 text-sm text-gray-700 w-full"
                                                                )}
                                                                onClick={() => {
                                                                    handleViewMyProfile();
                                                                }}
                                                            >
                                                                My Profile
                                                            </button>
                                                        )}
                                                    </MenuItem>{" "}
                                                    <MenuItem>
                                                        {({ focus }) => (
                                                            <button
                                                                className={classNames(
                                                                    focus ? "bg-gray-100" : "",
                                                                    "block px-4 py-2 text-sm text-gray-700 w-full"
                                                                )}
                                                            >
                                                                Settings
                                                            </button>
                                                        )}
                                                    </MenuItem>
                                                    <MenuItem>
                                                        {({ focus }) => (
                                                            <button
                                                                onClick={logout}
                                                                className={classNames(
                                                                    focus ? "bg-gray-100" : "",
                                                                    "block px-4 py-2 text-sm text-gray-700 w-full"
                                                                )}
                                                            >
                                                                Log out
                                                            </button>
                                                        )}
                                                    </MenuItem>
                                                </MenuItems>
                                            </Transition>
                                        </Menu>
                                    </>
                                ) : (
                                    <button
                                        onClick={() => {
                                            navigate("/login");
                                        }}
                                        className={classNames(
                                            "text-gray-300 hover:bg-gray-700 hover:text-white",
                                            "rounded-md px-3 py-2 text-sm font-medium"
                                        )}
                                    >
                                        Login
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {!loading && myProfile && isViewMyProfileModalOpen && (
                        <EmployeeDetails
                            isOpen={isViewMyProfileModalOpen}
                            onClose={handleViewMyProfileCloseModal}
                            user={myProfile}
                            operation="My Profile"
                        />
                    )}
                </>
            )}
        </Disclosure>
    );
}
