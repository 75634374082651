

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Checkbox,
  CheckboxGroup,
  Stack,
  Input,
  NumberInput,
  NumberInputField,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useClient } from "../../../context/ClientContext";
import { useJD } from "../../../context/JDContext";

const JdFilterModal = ({
  isOpen,
  onClose,
  operation = "Filter",
  initialPath = "",
}) => {
  const [selectedExperience, setSelectedExperience] = useState({
    min: "",
    max: "",
  });
  const {fetchFilteredJDs, setFilteredJDs,setFilterJdItem } = useJD();
  const [selectedSalary, setSelectedSalary] = useState({
    min: "",
    max: "",
  });
  const [selectedJobTitle, setSelectedJobTitle] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSkills, setFilteredSkills] = useState([]);

  const [isClearing, setIsClearing] = useState(false);
  const { fetchAllClient, allClients } = useClient();
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [selectedClients, setSelectedClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);

  useEffect(() => {
    if (initialPath === "admin_dashboard") {
      fetchAllClient("all", 1, 30);
    }
    if (initialPath === "bd_dashboard") {
      fetchAllClient("all", 1, 30);
    }
  }, []);

  // Create a name-to-id mapping for easy lookups
  const nameToIdMap = Array.isArray(allClients.all)
    ? allClients.all.reduce((map, client) => {
        map[client.name] = client._id; // Map name to _id
        return map;
      }, {})
    : {};

  // Extract all client names
  const allClientNames = Object.keys(nameToIdMap);

  // Filter logic
  useEffect(() => {
    if (clientSearchTerm) {
      const filtered = allClientNames.filter((name) =>
        name.toLowerCase().includes(clientSearchTerm.toLowerCase())
      );
      // Only update state if the filtered clients are different
      if (filtered.length !== filteredClients.length) {
        setFilteredClients(filtered);
      }
    } else {
      // Reset filteredClients if input is empty
      if (filteredClients.length !== 0) {
        setFilteredClients([]);
      }
    }
  }, [clientSearchTerm, allClientNames]);

  // Handle Enter key to add custom client
  const handleClientKeyPress = (e) => {
    if (e.key === "Enter" && clientSearchTerm.trim()) {
      const newClientName = clientSearchTerm.trim();
      const newClientId = nameToIdMap[newClientName]; // Get ID if it exists
      if (newClientId && !selectedClients.includes(newClientId)) {
        setSelectedClients([...selectedClients, newClientId]); // Store _id
      }
      setClientSearchTerm(""); // Clear input box
    }
  };

  // Handle client selection from suggestions
  const handleClientSelect = (clientName) => {
    const clientId = nameToIdMap[clientName]; // Get ID from name
    if (clientId && !selectedClients.includes(clientId)) {
      setSelectedClients([...selectedClients, clientId]); // Store _id
    }
    setClientSearchTerm(""); // Clear input box
  };

  // Handle checkbox changes (assume you get IDs from checkboxes)
  const handleClientChange = (checkedClientIds) => {
    setSelectedClients(checkedClientIds);
  };

  // Static skills array
  const [skillsArray, setSkillsArray] = useState([
    // Technical Skills
    "JavaScript",
    "Python",
    "Java",
    "C++",
    "C#",
    "Ruby",
    "Go",
    "Swift",
    "Kotlin",
    "HTML",
    "CSS",
    "TypeScript",
    "React",
    "Angular",
    "Vue",
    "Node.js",
    "Django",
    "Flask",
    "Ruby on Rails",
    "ASP.NET",
    "Spring Boot",
    "SQL",
    "NoSQL",
    "MongoDB",
    "PostgreSQL",
    "MySQL",
    "Oracle Database",
    "Firebase",
    "AWS",
    "Azure",
    "Google Cloud",
    "Docker",
    "Kubernetes",
    "Git",
    "CI/CD",
    "Jenkins",
    "Terraform",
    "Linux",
    "Unix",
    "Machine Learning",
    "Deep Learning",
    "Data Analysis",
    "Data Science",
    "Artificial Intelligence",
    "NLP",
    "Computer Vision",
    "Data Engineering",
    "Big Data",
    "Hadoop",
    "Spark",
    "Scala",
    "R Programming",
    "MATLAB",
    "SAS",
    "Tableau",
    "Power BI",
    "Excel",
    "Blockchain",
    "Cryptography",
    "Cybersecurity",
    "Penetration Testing",
    "Network Security",
    "Cloud Security",
    "Digital Forensics",
    "IoT",
    "Augmented Reality",
    "Virtual Reality",
    "Game Development",
    "Unreal Engine",
    "Unity",
    "Embedded Systems",
    "Microcontrollers",
    "Arduino",
    "Raspberry Pi",

    // Non-Technical Skills
    "Communication",
    "Teamwork",
    "Leadership",
    "Time Management",
    "Problem Solving",
    "Creativity",
    "Adaptability",
    "Critical Thinking",
    "Conflict Resolution",
    "Project Management",
    "Negotiation",
    "Decision Making",
    "Emotional Intelligence",
    "Interpersonal Skills",
    "Presentation Skills",
    "Public Speaking",
    "Customer Service",
    "Sales",
    "Marketing",
    "Brand Management",
    "Content Creation",
    "Social Media Marketing",
    "SEO",
    "Digital Marketing",
    "Copywriting",
    "Content Writing",
    "Blogging",
    "Editing",
    "Proofreading",
    "Event Planning",
    "Strategic Planning",
    "Market Research",
    "CRM",
    "Product Management",
    "Business Analysis",
    "Data Entry",
    "Administrative Skills",
    "Organizational Skills",
    "Self-Motivation",
    "Work Ethic",

    // IT and Non-IT Skills
    "Technical Support",
    "Help Desk",
    "System Administration",
    "Network Administration",
    "Database Administration",
    "ITIL",
    "ERP",
    "SAP",
    "Salesforce",
    "IT Compliance",
    "DevOps",
    "Automation",
    "Robotic Process Automation (RPA)",
    "Software Testing",
    "Manual Testing",
    "Automation Testing",
    "Quality Assurance",
    "Agile Methodologies",
    "Scrum",
    "Kanban",
    "JIRA",
    "Confluence",
    "IT Security",
    "Incident Management",
    "Disaster Recovery",
    "Risk Management",
    "Finance",
    "Accounting",
    "Taxation",
    "Auditing",
    "Financial Analysis",
    "Investment Management",
    "Trading",
    "Stock Market Analysis",
    "Wealth Management",
    "Real Estate Investment",
    "Financial Modeling",
    "Forecasting",

    // Mechanical and Engineering Skills
    "Mechanical Engineering",
    "Electrical Engineering",
    "Civil Engineering",
    "Chemical Engineering",
    "Biomedical Engineering",
    "Aerospace Engineering",
    "Automotive Engineering",
    "Structural Engineering",
    "Process Engineering",
    "CAD",
    "AutoCAD",
    "SolidWorks",
    "Fusion 360",
    "3D Printing",
    "CNC Programming",
    "Manufacturing",
    "Quality Control",
    "Welding",
    "Material Science",
    "Thermodynamics",
    "HVAC",
    "Pneumatics",
    "Hydraulics",
    "Machining",
    "Blueprint Reading",
    "Tooling",
    "Maintenance Engineering",
    "Electronics",
    "Circuit Design",
    "PLC Programming",
    "Instrumentation",
    "Control Systems",
    "Mechatronics",

    // Trading and Finance Skills
    "Stock Trading",
    "Day Trading",
    "Options Trading",
    "Forex Trading",
    "Commodity Trading",
    "Risk Analysis",
    "Portfolio Management",
    "Financial Planning",
    "Hedge Funds",
    "Derivatives",
    "Cryptocurrency Trading",
    "Blockchain Analysis",
    "Technical Analysis",
    "Fundamental Analysis",
    "Quantitative Analysis",
    "Valuation",
    "Corporate Finance",
    "Equity Research",
    "Venture Capital",
    "Private Equity",
    "Investment Banking",

    // Additional Miscellaneous Skills
    "Photography",
    "Videography",
    "Graphic Design",
    "UI/UX Design",
    "Illustrator",
    "Photoshop",
    "Sketch",
    "Figma",
    "Adobe XD",
    "After Effects",
    "3D Modeling",
    "Interior Design",
    "Fashion Design",
    "Cooking",
    "Baking",
    "Bartending",
    "Gardening",
    "Landscaping",
    "Woodworking",
    "Carpentry",
    "Plumbing",
    "Electrician",
    "Hair Styling",
    "Makeup Artistry",
    "Massage Therapy",
    "Yoga Instruction",
    "Fitness Training",
    "Nutrition",
    "First Aid",
    "Public Relations",
    "Fundraising",
    "Teaching",
    "Tutoring",
    "Language Translation",
    "Sign Language",
    "Foreign Language Proficiency",
    "Customer Relationship Management",
  ]);

  // Handle input changes
  const handleExperienceChange = (event, type) => {
    const value = event.target.value;
    setSelectedExperience((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };
  // Handle input changes
  const handleSalaryChange = (event, type) => {
    const value = event.target.value;
    setSelectedSalary((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const handleCtcChange = (event) => {
    const value = event.target.value;
    setSelectedJobTitle(value);
  };
  const handleSkillChange = (skills) => setSelectedSkills(skills);

  // Filter skills based on search term
  useEffect(() => {
    if (searchTerm) {
      setFilteredSkills(
        skillsArray.filter(
          (skill) =>
            skill.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !selectedSkills.includes(skill)
        )
      );
    } else {
      setFilteredSkills([]);
    }
  }, [searchTerm, selectedSkills]);

  // Add selected skill from suggestion
  const handleSkillSelect = (skill) => {
    setSelectedSkills((prevSkills) => [...prevSkills, skill]);
    setSearchTerm(""); // Clear search term after selection
  };

  // Check if any filters are applied
  const isFilterApplied =
    selectedExperience || selectedJobTitle || selectedSkills.length > 0;

  const applyFilters = () => {
    const filtersArray = [
      { type: "experience", value: selectedExperience }, // Includes min and max
      { type: "jobTitle", value: selectedJobTitle },
      { type: "skills", value: selectedSkills },
      { type: "salaryOffering", value: selectedSalary },
      { type: "client", value: selectedClients },
    ];
    setFilterJdItem([])
    fetchFilteredJDs(filtersArray);
    setFilterJdItem(filtersArray)
    onClose();
  };

  const clearFilters = () => {
    setFilteredJDs([]);
    setSelectedExperience({ min: "", max: "" });
    setSelectedSalary({ min: "", max: "" });
    setSelectedJobTitle("");
    setSelectedSkills([]);
    setSelectedClients([]);
    setFilterJdItem([])
    setIsClearing(true);
  };

  // Handle pressing Enter in the search input
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchTerm) {
      // Check if the skill already exists in the selectedSkills or skillsArray
      if (
        !skillsArray.includes(searchTerm) &&
        !selectedSkills.includes(searchTerm)
      ) {
        // Add the new skill to the skillsArray dynamically
        setSkillsArray((prevSkills) => [...prevSkills, searchTerm]);
      }
      // Add the skill to selectedSkills
      handleSkillSelect(searchTerm);
    }
  };

  useEffect(() => {
    if (isClearing) {
      setIsClearing(false);
      
      onClose();
    }
  }, [isClearing, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent
        position="absolute" // Set the positioning type
        top="0%" // Move it down from the top
        right="2%" // Move it right from the left
        transform="translate(-25%, 0)" // Adjust positioning if needed
      >
        <ModalHeader>{operation} Options</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={2} mb={2}>
            {/* Experience Section */}
            <Flex
              direction="row" // Set layout to row for side-by-side display
              spacing={2}
              p={2}
              justifyContent="space-between" // Add space between the items
            >
              <Box width="48%">
                {" "}
                {/* Match width with the Experience section */}
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Job Title
                </Text>
                <Input
                  placeholder="Developer..."
                  value={selectedJobTitle}
                  onChange={handleCtcChange}
                  mb={4}
                />
              </Box>
              {/* Experience Section */}
              <Box width="48%">
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Experience (Years)
                </Text>
                <Flex gap={2} mb={4}>
                  <Input
                    placeholder="Min (e.g., 0)"
                    value={selectedExperience.min}
                    onChange={(e) => handleExperienceChange(e, "min")}
                  />
                  <Input
                    placeholder="Max (e.g., 5)"
                    value={selectedExperience.max}
                    onChange={(e) => handleExperienceChange(e, "max")}
                  />
                </Flex>
              </Box>

              {/* CTC Section */}
            </Flex>

            {/* Skills Section */}
            <Text fontSize="lg" fontWeight="bold">
              Skills
            </Text>
            <Input
              placeholder="Search or add a skill"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress} // Handle Enter key for adding custom skill
              mb={3}
            />
            <CheckboxGroup value={selectedSkills} onChange={handleSkillChange}>
              <Stack spacing={2}>
                {selectedSkills.map((skill) => (
                  <Checkbox key={skill} value={skill}>
                    {skill}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>

            {/* Suggested Skills */}
            {filteredSkills.length > 0 && (
              <Box
                mt={2}
                border="1px solid #ccc"
                borderRadius="md"
                p={2}
                maxHeight="200px" // Adjust the max height as needed
                overflowY="auto" // Enables vertical scrolling
              >
                <Text fontSize="sm" fontWeight="bold" mb={2}>
                  Suggested Skills
                </Text>
                <Stack spacing={1}>
                  {filteredSkills.map((skill) => (
                    <Button
                      key={skill}
                      variant="ghost"
                      onClick={() => handleSkillSelect(skill)}
                      size="sm"
                    >
                      {skill}
                    </Button>
                  ))}
                </Stack>
              </Box>
            )}
            <Box width="48%">
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Salary Offering range
              </Text>
              <Flex gap={2} mb={4}>
                <Input
                  placeholder="Min (e.g., 0)"
                  value={selectedSalary.min}
                  onChange={(e) => handleSalaryChange(e, "min")}
                />
                <Input
                  placeholder="Max (e.g., 5)"
                  value={selectedSalary.max}
                  onChange={(e) => handleSalaryChange(e, "max")}
                />
              </Flex>
            </Box>
            {/* Clients Section */}
            {(initialPath && initialPath == "bd_dashboard") ||
              (initialPath == "admin_dashboard" && (
                <>
                  <Text fontSize="lg" fontWeight="bold">
                    Company Name
                  </Text>
                  <Input
                    placeholder="Search or add a client"
                    value={clientSearchTerm}
                    onChange={(e) => setClientSearchTerm(e.target.value)}
                    onKeyPress={handleClientKeyPress}
                    mb={3}
                  />
                  <CheckboxGroup
                    value={selectedClients} // Store IDs here
                    onChange={handleClientChange}
                  >
                    <Stack spacing={2}>
                      {selectedClients.map((clientId) => {
                        // Reverse map ID to name for display
                        const clientName = Object.keys(nameToIdMap).find(
                          (name) => nameToIdMap[name] === clientId
                        );
                        return (
                          <Checkbox key={clientId} value={clientId}>
                            {clientName || "Unknown Client"}
                          </Checkbox>
                        );
                      })}
                    </Stack>
                  </CheckboxGroup>

                  {/* Suggested Clients */}
                  {filteredClients.length > 0 && (
                    <Box
                      mt={2}
                      border="1px solid #ccc"
                      borderRadius="md"
                      p={2}
                      maxHeight="200px"
                      overflowY="auto"
                    >
                      <Text fontSize="sm" fontWeight="bold" mb={2}>
                        Suggested Clients
                      </Text>
                      <Stack spacing={1}>
                        {filteredClients.map((clientName) => (
                          <Button
                            key={clientName}
                            variant="ghost"
                            onClick={() => handleClientSelect(clientName)}
                            size="sm"
                          >
                            {clientName}
                          </Button>
                        ))}
                      </Stack>
                    </Box>
                  )}
                </>
              ))}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={applyFilters}
            isDisabled={!isFilterApplied}
          >
            Apply Filters
          </Button>

          <Button variant="ghost" colorScheme="red" onClick={clearFilters}>
            Clear Filters
          </Button>

          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default JdFilterModal;
