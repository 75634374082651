import React, { useEffect, useState, useRef } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tag,
  TagLabel,
  Box,
  Text,
  Avatar,
  Heading,
  Flex,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Select,
  UnorderedList,
  ListItem,
  Skeleton,
  Button,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import { FaCircle, FaFilter, FaSort, FaTimes } from "react-icons/fa";
import { MdMenu, MdPlaylistAdd } from "react-icons/md";
import { BsSlashCircle } from "react-icons/bs";
import { IoMdFemale, IoMdMale } from "react-icons/io";
import { IoMaleFemale } from "react-icons/io5";

import { useEnv } from "../../../context/EnvContext";

import {
  formatDate,
  formatDateTime,
} from "../../../Utils/GlobalFunctions/FormatDate";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";
import { printSkills } from "../../../Utils/GlobalFunctions/PrintSkills";

import { useCandidate } from "../../../context/CandidateContext";
import { useApplication } from "../../../context/ApplicationContext";
import CandidateDetails from "../Candidate/CandidateDetails";
import CandidateFilterModal from "../Candidate/CandidateFilterModal";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { debounce } from "../../../Utils/GlobalFunctions/Debounce";

const StartApplication = ({ isOpen, onClose, jd }) => {
  const { backendUrl } = useEnv();
  const {
    fetchMatchingCandidates,
    allMatchingCandidates,
    hasMoreMatchingCandidates,
    matchingCandidatesPageNo,
    filteredCandidates,
    setFilteredCandidates,
    hasMoreFilteredCandidates,
    filteredCandidatesPageNo,
    fetchFilteredCandidates,
    setFilterItem,
    loading,
  } = useCandidate();
  const { startApplication } = useApplication();
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isCandidateDetailsModalOpen, setIsCandidateDetailsModalOpen] =
    useState(false);
  const [candidateStatus, setCandidateStatus] = useState("bench");
  const [headingStatusText, setHeadingStatusText] = useState("");
  const [isCandidateFilterModalOpen, setIsCandidateFilterModalOpen] =
    useState(false);

  // const containerRef = useRef(null); // Reference for the scrollable div
  // const endRef = useRef(null);
  // const pageNoRef = useRef(filteredCandidatesPageNo);
  // const [scrollInitiated, setScrollInitiated] = useState(false); // Track if user has scrolled
  // const [isInitialLoad, setIsInitialLoad] = useState(true); // Track initial load

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    fetchMatchingCandidates(candidateStatus, 1, 30, false, jd?._id);
  }, [candidateStatus]);

  useEffect(() => {
    switch (candidateStatus) {
      case "all":
        setHeadingStatusText("All");
        break;
      case "selected":
        setHeadingStatusText("Selected");
        break;
      case "processing":
        setHeadingStatusText("Processing");
        break;
      case "bench":
        setHeadingStatusText("On Bench");
        break;
      default:
        setHeadingStatusText("All");
        break;
    }
  }, [candidateStatus]);


  // // Update pageNoRef whenever pageNo changes
  // useEffect(() => {
  //   pageNoRef.current = filteredCandidatesPageNo;
  // }, [filteredCandidatesPageNo]);

  // const fetchMoreData = async () => {
  //   if (loading || !hasMoreFilteredCandidates || !scrollInitiated) return;

  //   await fetchFilteredCandidates(
  //     candidateStatus,
  //     pageNoRef.current,
  //     30,
  //     true,
  //     jd?._id
  //   );
  //   setIsInitialLoad(false); // Update after initial load
  // };

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting && scrollInitiated) {
  //         //console.log("Fetching more data?...");
  //         fetchMoreData();
  //       }
  //     },
  //     {
  //       root: containerRef.current,
  //       rootMargin: "0px 0px 100px 0px", // Adjusted to trigger earlier for smoother loading
  //       threshold: 0.5, // Increased to trigger loading more reliably
  //     }
  //   );

  //   // Only scroll to the bottom initially or after data is fetched
  //   if (isInitialLoad && containerRef.current) {
  //     containerRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  //   if (endRef.current) {
  //     // endRef.current.scrollIntoView({ behavior: "smooth" });
  //     observer.observe(endRef.current);
  //   }

  //   return () => {
  //     if (endRef.current) {
  //       observer.unobserve(endRef.current);
  //     }
  //   };
  // }, [hasMoreFilteredCandidates, loading, scrollInitiated, isInitialLoad]);

  // const handleScroll = debounce(() => {
  //   if (containerRef?.current?.scrollTop > 0) {
  //     setScrollInitiated(true);
  //   }
  // }, 300); // Adjust the delay as needed

  useEffect(() => {
    if (filteredCandidates?.length > 0) {
        const updatedFilterAdd = {
            candidateId: filteredCandidates.map((cd) => ({
                candidateId: cd, // Directly assigning the element to jobDescriptionId
          })),
        };
        setSortedData(updatedFilterAdd.candidateId);
      } else {
    setSortedData(allMatchingCandidates[candidateStatus]);
      }
  }, [candidateStatus, allMatchingCandidates,filteredCandidates]);

  const handleCandidateFilter = () => {
    setIsCandidateFilterModalOpen(true);
  };
  const handleCandidateFilterCloseModal = () => {
    setIsCandidateFilterModalOpen(false);
  };

  const handleClearFilter = () => {
    setFilteredCandidates([]);
    setFilterItem([])
  };

  const handleCandidatePreview = (candidate) => {
    setSelectedCandidate(candidate);
    setIsCandidateDetailsModalOpen(true);
  };

  const handleCandidateViewCloseModal = () => {
    setIsCandidateDetailsModalOpen(false);
    setSelectedCandidate(null);
  };

  const handleStartApplication = (candidateId, mpId) => {
    startApplication(candidateId, jd?._id, candidateStatus, mpId, "procesJD");
  };

  return (
    <div>
      <Modal
        isLazy
        isOpen={isOpen}
        onClose={onClose}
        size={"full"}
        height={"100vh"}
      >
        <ModalOverlay />
        <ModalContent className="jd-details">
          <ModalHeader background={"black"} p={2} position="relative">
            <Heading as="h4" size="md" textAlign={"center"}>
              Allocate Candidates for {jd?.jobId}
            </Heading>
            <Button
              bg={tagColor("purple").bg}
              color={tagColor("purple").clr}
              px={2}
              borderRadius={20}
              height={"25px"}
              fontWeight="bold"
              fontSize={"sm"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
              position="absolute" // Makes it relative to the ModalHeader
              right="60px" // Adjust the position from the right edge
              top="60%" // Center vertically
              transform="translateY(-50%)" // Adjust for perfect centering
              onMouseEnter={(e) => {
                e.target.style.background = "#3B4A5A";
              }}
              onMouseLeave={(e) => {
                e.target.style.background = "#1F2937";
              }}
              onClick={() => {
                handleCandidateFilter();
              }}
            >
              Filter
              {filteredCandidates?.length > 0 ? (
                <Box
                  height="25px"
                  ml={0}
                  width="fit-content"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  padding="0"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClearFilter(e);
                  }}
                >
                  <FaTimes size={20} color="red" />
                </Box>
              ) : (
                <FaFilter />
              )}
            </Button>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            style={{
              paddingBottom: "20px",
              background: "linear-gradient(180deg, black, rgb(29, 48, 75))",
            }}
          >
            <Flex gap={2}>
              <Box
                style={{ height: `calc(100vh - 85px)` }}
                overflowY={"auto"}
                width={"35%"}
                pr={2}
              >
                <Box mb={3}>
                  <Heading as="h4" size="md" mb={2}>
                    {jd?.jobId} - {jd?.jobTitle}
                  </Heading>
                  <Box>
                    {jd?.similarTags?.map((tag, index) => (
                      <Tag
                        key={index}
                        size="md"
                        borderRadius="full"
                        variant="solid"
                        m={"2px"}
                      >
                        <TagLabel>{tag}</TagLabel>
                      </Tag>
                    ))}
                  </Box>
                </Box>
                <UnorderedList>
                  <ListItem>
                    <b>Job Description:</b> {jd?.description}
                  </ListItem>
                  <ListItem>
                    <b>Company:</b> {jd?.clientDetails?.client?.name}
                  </ListItem>
                  <ListItem>
                    <b>Required Skills:</b>{" "}
                    {jd?.skills?.map((skill, index) => (
                      <Tag
                        key={index}
                        m={"2px"}
                        size="md"
                        borderRadius="full"
                        variant="solid"
                      >
                        <TagLabel>{`${skill.skillName} - ${skill.relevantExp} Years`}</TagLabel>
                      </Tag>
                    ))}
                  </ListItem>
                  <ListItem>
                    <b>Optional Skills:</b>{" "}
                    {jd?.optionalSkills?.map((skill, index) => (
                      <Tag
                        key={index}
                        m={"2px"}
                        size="md"
                        borderRadius="full"
                        variant="solid"
                      >
                        <TagLabel>{skill}</TagLabel>
                      </Tag>
                    ))}
                  </ListItem>
                  <ListItem>
                    <b>Overall Experience:</b> {jd?.experience} Years
                  </ListItem>
                  <ListItem>
                    <Flex gap={1} align={"center"}>
                      <b>Experience Weightage:</b>
                      <Flex
                        align={"center"}
                        justify={"center"}
                        height={"20px"}
                        paddingX={2}
                        boxSizing="border-box"
                        flexGrow={1}
                      >
                        <Tooltip
                          hasArrow
                          label={`Overall Experience Weightage: ${jd?.expValidity}%`}
                          bg="gray.300"
                          color="black"
                          placement="top"
                        >
                          <Box
                            width={`${jd?.expValidity}%`}
                            height={"80%"}
                            background={"#eb73e2"}
                            style={{
                              borderRadius: "10px 0px 0px 10px",
                            }}
                          >
                            <Text
                              align={"center"}
                              fontSize={"xs"}
                              color={"black"}
                              fontWeight={"bold"}
                              lineHeight={1.2}
                            >
                              {jd?.expValidity} %
                            </Text>
                          </Box>
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label={`Relevant Experience Weightage: ${jd?.relExpValidity}%`}
                          bg="gray.300"
                          color="black"
                          placement="top"
                        >
                          <Box
                            width={`${jd?.relExpValidity}%`}
                            height={"80%"}
                            background={"#fbd1a2"}
                            style={{
                              borderRadius: "0px 10px 10px 0px",
                            }}
                          >
                            <Text
                              align={"center"}
                              fontSize={"xs"}
                              color={"black"}
                              fontWeight={"bold"}
                              lineHeight={1.2}
                            >
                              {jd?.relExpValidity} %
                            </Text>
                          </Box>
                        </Tooltip>
                      </Flex>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <b>Salary Offering:</b> {jd?.salaryOffering}
                  </ListItem>
                  <ListItem>
                    <b>No. of Open Positions:</b> {jd?.numberOfOpenings}
                  </ListItem>
                  <ListItem>
                    <b>No. of Closed Positions:</b> {jd?.noOfClosings}
                  </ListItem>
                  <ListItem>
                    <b>Closing Date:</b> {formatDate(jd?.closingDate)}
                  </ListItem>
                  <ListItem>
                    <b>Percentage Share:</b> {jd?.percentage} %
                  </ListItem>
                  <ListItem>
                    <b>Working Recruiters:</b>{" "}
                    <UnorderedList styleType="'-'">
                      {jd?.workingRecruiters?.map((recruiter, index) => (
                        <ListItem key={index}>
                          <Tag size="lg" borderRadius="full" m={"2px"}>
                            <Avatar
                              src={`${backendUrl}${recruiter.dp}`}
                              size="xs"
                              name={recruiter.name}
                              ml={-1}
                              mr={2}
                            />
                            <TagLabel fontSize={"sm"}>
                              ({recruiter.empId}) {recruiter.name}
                            </TagLabel>
                          </Tag>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    <b>Brought By:</b>{" "}
                    <Tag size="lg" borderRadius="full">
                      <Avatar
                        src={`${backendUrl}${jd?.broughtBy.dp}`}
                        size="xs"
                        name={jd?.broughtBy.name}
                        ml={-1}
                        mr={2}
                      />
                      <TagLabel fontSize={"sm"}>
                        <Text fontSize={"sm"}>
                          ({jd?.broughtBy.empId}) {jd?.broughtBy.name} on{" "}
                          {formatDateTime(jd?.createdAt)}
                        </Text>
                      </TagLabel>
                    </Tag>
                  </ListItem>
                  {jd?.selectedApplications && (
                    <ListItem>
                      <b>Selected Candidates:</b>{" "}
                      {jd?.selectedApplications?.map((application, index) => (
                        <Tag key={index} size="lg" borderRadius="full" mr={2}>
                          <Avatar
                            src={`${backendUrl}${application.candidate.dp}`}
                            size="xs"
                            name={application.candidate.name}
                            ml={-1}
                            mr={2}
                          />
                          <TagLabel fontSize={"sm"}>
                            {" "}
                            ({application.applicationID}) (
                            {application.candidate.stuId}){" "}
                            {application.candidate.name}
                          </TagLabel>
                        </Tag>
                      ))}
                    </ListItem>
                  )}
                </UnorderedList>
              </Box>
              <Box flexGrow={1}>
                <Flex justify={"space-between"} align="center" mb={2}>
                  <Heading as="h4" size="md" textAlign={"center"}>
                    {headingStatusText} Matching Candidates
                  </Heading>
                  <Select
                    name="gender"
                    value={candidateStatus}
                    onChange={(e) => {
                      //console.log(e.target.value);
                      setCandidateStatus(e.target.value);
                    }}
                    w={"150px"}
                  >
                    <option value="all">All</option>
                    <option value="selected">Selected</option>
                    <option value="processing">Processing</option>
                    <option value="bench">On Bench</option>
                  </Select>
                </Flex>
                <div
                  style={{
                    height: "80vh",
                    overflow: "auto",
                    position: "relative",
                  }}
                >
                  {/* <div
                    ref={containerRef}
                    style={{
                      height: "75vh",
                      overflow: "auto",
                      position: "relative",
                    }}
                    onScroll={handleScroll} // Trigger scroll detection
                  > */}
                    <TableContainer style={{ position: "relative" }}>
                      <Table size="sm">
                        <Thead
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <Tr>
                            <Th
                              style={{
                                color: "white",
                              }}
                              p={1}
                            >
                              <Tooltip
                                hasArrow
                                label={
                                  <Text fontSize={"xs"}>
                                    Sl. No. with tick mark if respective
                                    candidate is processed for this JD with
                                    application Id and Application currentstate.
                                    View Application Id and currentstate by
                                    hovering mouse over the tick mark
                                  </Text>
                                }
                                bg="gray.300"
                                color="black"
                                placement="top"
                              >
                                Sl
                              </Tooltip>
                            </Th>
                            {/* <Th
                                                            style={{
                                                                color: "white",
                                                            }}
                                                            p={0}
                                                        >
                                                            <Checkbox
                                                                size="sm"
                                                                mt={"2px"}
                                                            ></Checkbox>
                                                        </Th> */}
                            <Th
                              style={{
                                color: "white",
                              }}
                              pr={0}
                            >
                              <Tooltip
                                hasArrow
                                label={
                                  <Flex align={"center"} direction={"column"}>
                                    <Text>Student Id</Text>
                                    <Text fontSize={"xs"} color={"#2b2a2a"}>
                                      Click to view Candidate Details
                                    </Text>
                                  </Flex>
                                }
                                bg="gray.300"
                                color="black"
                                placement="top"
                              >
                                St. Id
                              </Tooltip>
                            </Th>
                            <Th
                              style={{
                                color: "white",
                              }}
                              pr={0}
                            >
                              <Flex
                                alignItems={"center"}
                                width={"100%"}
                                justify={"space-between"}
                              >
                                <Tooltip
                                  hasArrow
                                  label={
                                    <>
                                      <Flex align={"center"}>
                                        <Avatar
                                          src=""
                                          size="sm"
                                          name="Display Picture"
                                          mr={2}
                                          background={"green"}
                                          color={"white"}
                                        />
                                        <Box
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <Text
                                            fontSize={"sm"}
                                            style={{
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              maxWidth: "180px",
                                            }}
                                          >
                                            Gender - Candidate Name
                                          </Text>
                                          <Text
                                            fontSize={"sm"}
                                            color={"#2b2a2a"}
                                            style={{
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              maxWidth: "180px",
                                            }}
                                          >
                                            Candidate Email
                                          </Text>
                                        </Box>
                                      </Flex>
                                      <Text fontSize={"xs"} color={"gray"}>
                                        click on mail to mail the candidate
                                        directly
                                      </Text>
                                      <Flex
                                        direction={"column"}
                                        fontSize={"xs"}
                                        color={"gray"}
                                        mt={2}
                                      >
                                        <Text color={"black"}>
                                          Gender Details:{" "}
                                        </Text>
                                        <Flex
                                          align={"center"}
                                          gap={1}
                                          background={"black"}
                                          p={1}
                                          px={2}
                                          color={"white"}
                                        >
                                          <IoMdMale color="#2D9AFE" />
                                          <Text>Male, </Text>
                                          <IoMdFemale color="#F86CFF" />
                                          <Text>Female, </Text>
                                          <IoMaleFemale color="#ffd600" />
                                          <Text>Other</Text>
                                        </Flex>
                                      </Flex>
                                    </>
                                  }
                                  bg="gray.300"
                                  color="black"
                                  placement="top"
                                >
                                  Candidate
                                </Tooltip>
                              </Flex>
                            </Th>

                            <Th
                              style={{
                                color: "white",
                                maxWidth: "200px",
                              }}
                              pr={0}
                            >
                              <Tooltip
                                hasArrow
                                label={
                                  <Flex align={"center"} direction={"column"}>
                                    <Text>
                                      Candidate Skills (Rel. Exp. in years)
                                    </Text>
                                    <Text fontSize={"xs"} color={"#2b2a2a"}>
                                      Click to view Skills with relavant
                                      experience in details
                                    </Text>
                                  </Flex>
                                }
                                bg="gray.300"
                                color="black"
                                placement="top"
                              >
                                Skills with rel. Exp.
                              </Tooltip>
                            </Th>
                            <Th
                              style={{
                                color: "white",
                              }}
                              p={0}
                            >
                              <Tooltip
                                hasArrow
                                label="Percentage Matching"
                                bg="gray.300"
                                color="black"
                                placement="top"
                              >
                                % mat.
                              </Tooltip>
                            </Th>
                            <Th
                              style={{
                                color: "white",
                              }}
                            >
                              <Tooltip
                                hasArrow
                                label={
                                  <>
                                    <Flex
                                      direction={"column"}
                                      fontSize={"xs"}
                                      borderLeft={"3px solid"}
                                      borderColor="green"
                                      paddingLeft={1}
                                    >
                                      <Text fontSize={"xs"} fontWeight={"bold"}>
                                        Experience Period
                                      </Text>
                                      <Flex gap={1} alignItems={"center"}>
                                        <Text
                                          fontSize={"xs"}
                                          color={"#2b2a2a"}
                                          style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "200px",
                                          }}
                                        >
                                          (Experience Type)
                                        </Text>
                                      </Flex>
                                    </Flex>
                                    <Box
                                      mt={1}
                                      paddingLeft={1}
                                      borderLeft={"3px solid"}
                                      borderColor="red"
                                    >
                                      Fresher
                                    </Box>
                                    <Box
                                      mt={1}
                                      paddingLeft={1}
                                      borderLeft={"3px solid"}
                                      borderColor="green"
                                    >
                                      Experienced
                                    </Box>
                                  </>
                                }
                                bg="gray.300"
                                color="black"
                                placement="top"
                              >
                                Experience
                              </Tooltip>
                            </Th>
                            {candidateStatus == "all" && (
                              <Th
                                style={{
                                  color: "white",
                                }}
                                pr={0}
                              >
                                <Flex
                                  alignItems={"center"}
                                  width={"100%"}
                                  justify={"space-between"}
                                >
                                  <Tooltip
                                    hasArrow
                                    label={
                                      <>
                                        <Text>Candidate Status</Text>
                                        <hr
                                          style={{
                                            borderColor: "black",
                                          }}
                                        />
                                        <Flex align={"center"} gap={1}>
                                          <FaCircle
                                            style={{
                                              color: "greenyellow",
                                            }}
                                          />
                                          <Text>- Selected</Text>
                                        </Flex>
                                        <Flex align={"center"} gap={1}>
                                          <FaCircle
                                            style={{
                                              color: "#00aaff",
                                            }}
                                          />
                                          <Text>- On Processing</Text>
                                        </Flex>
                                        <Flex align={"center"} gap={1}>
                                          <FaCircle
                                            style={{
                                              color: "#ff6c00",
                                            }}
                                          />
                                          <Text>- On Bench</Text>
                                        </Flex>
                                      </>
                                    }
                                    bg="gray.300"
                                    color="black"
                                    placement="top"
                                  >
                                    St.
                                  </Tooltip>{" "}
                                </Flex>
                              </Th>
                            )}
                            <Th
                              style={{
                                color: "white",
                              }}
                            >
                              <Tooltip
                                hasArrow
                                label="Menu button for processing candidate for JDs"
                                bg="gray.300"
                                color="black"
                                placement="top"
                              >
                                <Box>
                                  <MdMenu />
                                </Box>
                              </Tooltip>
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {sortedData &&
                            sortedData?.map((data, index) => (
                              <Tr key={index}>
                                <Td p={1} position={"relative"}>
                                  {index + 1}
                                  {data?.addingStatus && (
                                    <Box
                                      position={"absolute"}
                                      bottom={"5px"}
                                      left={"0px"}
                                      cursor={"pointer"}
                                    >
                                      <Tooltip
                                        hasArrow
                                        label={
                                          <>
                                            <Text fontSize={"xs"}>
                                              Candidate{" "}
                                              <b>{data?.candidateId?.stuId}</b>{" "}
                                              added to <b>{jd?.jobId}</b>
                                            </Text>
                                            <hr />
                                            <Text>
                                              <b>Application Id-</b>{" "}
                                              {data?.applicationID}
                                            </Text>
                                            <Text>
                                              <b>Application Status-</b>{" "}
                                              {data?.currentState}
                                            </Text>
                                          </>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="bottom"
                                      >
                                        ✔️
                                      </Tooltip>
                                    </Box>
                                  )}
                                </Td>
                                {/* <Td p={0}>
                                                                    <Checkbox
                                                                        size="sm"
                                                                        mt={"2px"}
                                                                    ></Checkbox>
                                                                </Td> */}
                                <Td
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleCandidatePreview(data?.candidateId)
                                  }
                                >
                                  {data?.candidateId?.stuId}
                                </Td>
                                <Td
                                  style={{
                                    maxWidth: "250px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Flex>
                                    <Avatar
                                      isLazy
                                      src={`${backendUrl}${data?.candidateId.dp}`}
                                      size="sm"
                                      name={data?.candidateId?.name}
                                      mr={2}
                                      background={"green"}
                                      color={"white"}
                                    />
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Flex gap={1}>
                                        <Text
                                          fontSize={"sm"}
                                          style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "180px",
                                          }}
                                        >
                                          {data?.candidateId?.name}
                                        </Text>
                                        {getGenderIcon(data?.candidateId.gender)}
                                      </Flex>
                                      <Text
                                        fontSize={"sm"}
                                        color={"#a3a3a3"}
                                        style={{
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          maxWidth: "180px",
                                        }}
                                      >
                                        <a
                                          target="_blank"
                                          href={`mailto:${data?.candidateId?.email}`}
                                        >
                                          {data?.candidateId?.email}
                                        </a>
                                      </Text>
                                    </Box>
                                  </Flex>
                                </Td>
                                <Td
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "200px",
                                  }}
                                >
                                  <Popover>
                                    <PopoverTrigger>
                                      <Text cursor={"pointer"}>
                                        {printSkills(
                                          data?.candidateId?.skills,
                                          25
                                        )}
                                      </Text>
                                    </PopoverTrigger>
                                    <PopoverContent w={"200px"}>
                                      <PopoverArrow />
                                      <PopoverCloseButton />
                                      <PopoverHeader>Skills</PopoverHeader>
                                      <PopoverBody
                                        style={{
                                          textWrap: "wrap",
                                          lineHeight: "normal",
                                        }}
                                      >
                                        {data?.candidateId.skills?.map(
                                          (skill, index) => (
                                            <Text
                                              key={index}
                                            >{`${skill.skillName} - ${skill.relevantExp} Years`}</Text>
                                          )
                                        )}
                                      </PopoverBody>
                                    </PopoverContent>
                                  </Popover>
                                </Td>
                                <Td p={0}>
                                  {data?.percentageMatching
                                    ? parseFloat(
                                        data?.percentageMatching.toFixed(2)
                                      )
                                    : 0}{" "}
                                  %
                                </Td>
                                <Td>
                                  <Flex
                                    direction={"column"}
                                    fontSize={"xs"}
                                    borderLeft={"3px solid"}
                                    borderColor={
                                      data?.candidateId?.isFresher
                                        ? "red"
                                        : "green"
                                    }
                                    paddingLeft={1}
                                  >
                                    <Text fontSize={"xs"} fontWeight={"bold"}>
                                      {data?.candidateId?.experience}
                                    </Text>
                                    <Text fontSize={"xs"}>
                                      ({data?.candidateId?.experienceType})
                                    </Text>
                                  </Flex>
                                </Td>
                                {candidateStatus == "all" && (
                                  <Td pr={0}>
                                    {data?.candidateId?.currentStatus ==
                                      "Selected" && (
                                      <FaCircle
                                        style={{
                                          color: "greenyellow",
                                        }}
                                      />
                                    )}
                                    {data?.candidateId?.currentStatus ==
                                      "Processing" && (
                                      <FaCircle
                                        style={{
                                          color: "#00aaff",
                                        }}
                                      />
                                    )}
                                    {data?.candidateId?.currentStatus ==
                                      "On Bench" && (
                                      <FaCircle
                                        style={{
                                          color: "#ff6c00",
                                        }}
                                      />
                                    )}
                                  </Td>
                                )}
                                <Td>
                                  {data?.addingStatus ? (
                                    <>
                                      <Tooltip
                                        hasArrow
                                        label="Already added"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                      >
                                        <Box>
                                          <BsSlashCircle
                                            style={{
                                              height: "10px",
                                              color: "#fd9494",
                                            }}
                                          />
                                        </Box>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <Tooltip
                                      hasArrow
                                      label={
                                        <>
                                          Click to Process-{" "}
                                          <b>{data?.candidateId?.stuId}</b>
                                        </>
                                      }
                                      bg="gray.300"
                                      color="black"
                                      placement="top"
                                    >
                                      <Box>
                                        <MdPlaylistAdd
                                          cursor={"pointer"}
                                          onClick={() =>
                                            handleStartApplication(
                                              data?.candidateId._id,
                                              data?._id
                                            )
                                          }
                                        />
                                      </Box>
                                    </Tooltip>
                                  )}
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </TableContainer>

                    {/* {(loading || hasMoreFilteredCandidates) && (
                      <>
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                      </>
                    )}
                    {!hasMoreFilteredCandidates && !loading && (
                      <p style={{ textAlign: "center", marginTop: "5px" }}>
                        <b>End of the List</b>
                      </p>
                    )}
                    <div ref={endRef} style={{ height: "1px" }}></div>
                  </div> */}
                  {selectedCandidate && (
                    <CandidateDetails
                      candidate={selectedCandidate}
                      isOpen={isCandidateDetailsModalOpen}
                      onClose={handleCandidateViewCloseModal}
                    />
                  )}
                </div>
              </Box>
            </Flex>
          </ModalBody>
          {isCandidateFilterModalOpen && (
            <CandidateFilterModal
              operation={"Filter"}
              isOpen={isCandidateFilterModalOpen}
              onClose={handleCandidateFilterCloseModal}
            />
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default StartApplication;
