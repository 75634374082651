import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  RadioGroup,
  Radio,
  Select,
  VStack,
  Textarea,
  Box,
  Flex,
  Heading,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  IconButton,
  Avatar,
  Spinner,
  Center,
} from "@chakra-ui/react";
import * as Yup from "yup";

import { EditIcon } from "@chakra-ui/icons";
import { labelBaseStyle } from "../../../Utils/GlobalFunctions/miniFormLabel";
import { convertISOToDate } from "../../../Utils/GlobalFunctions/FormatDate";
import SkillsInput from "../../../Utils/GlobalComponents/SkillsInput";

import { useCandidate } from "../../../context/CandidateContext";
import { useEnv } from "../../../context/EnvContext";
import AutoFillModal from "./AutoFillModal";

// const workDetailsValidationSchema = Yup.object().shape({
//     companyName: Yup.string().required("* Company name is required"),
//     city: Yup.string().required("* City is required"),
//     state: Yup.string().required("* State is required"),
//     country: Yup.string().required("* Country is required"),
//     startingDate: Yup.date().required("* Starting date is required"),
//     endingDate: Yup.date(),
//     role: Yup.string().required("* Designation is required"),
//     workDetails: Yup.string().required("* Work details are required"),
// });

const PreviousWorkDetailsInput = ({ workDetails, setWorkDetails }) => {
  const [inputValue, setInputValue] = useState({
    companyName: "",
    companyAddress: {
      city: "",
      state: "",
      country: "",
    },
    startingDate: "",
    endingDate: "",
    role: "",
    workDetails: "",
  });

  const [editIndex, setEditIndex] = useState(-1);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => {
      if (
        name === "companyName" ||
        name === "startingDate" ||
        name === "endingDate" ||
        name === "workDetails" ||
        name === "role"
      ) {
        return { ...prev, [name]: value };
      } else {
        return {
          ...prev,
          companyAddress: {
            ...prev.companyAddress,
            [name]: value,
          },
        };
      }
    });
  };

  const handleSubmit = () => {
    if (editIndex === -1) {
      setWorkDetails([...workDetails, inputValue]);
    } else {
      const updatedWorkDetails = workDetails.map((work, index) =>
        index === editIndex ? inputValue : work
      );
      setWorkDetails(updatedWorkDetails);
      setEditIndex(-1);
    }
    setInputValue({
      companyName: "",
      companyAddress: {
        city: "",
        state: "",
        country: "",
      },
      startingDate: "",
      endingDate: "",
      workDetails: "",
      role: "",
    });
  };
  const handleClear = (index) => {
    setEditIndex(-1);

    setInputValue({
      companyName: "",
      companyAddress: {
        city: "",
        state: "",
        country: "",
      },
      startingDate: "",
      endingDate: "",
      workDetails: "",
      role: "",
    });
  };
  const removeWork = (workToRemove) => {
    setWorkDetails(workDetails.filter((work) => work !== workToRemove));
  };

  const editWork = (index) => {
    setInputValue(workDetails[index]);
    setEditIndex(index);
  };

  return (
    <FormControl id="workDetails">
      <FormLabel mb={1}>Work Details</FormLabel>
      <Box p={2} border="1px" borderColor={"inherit"} borderRadius={"md"}>
        <VStack spacing={1} align="stretch">
          <FormControl id="companyName" position="relative" mt={3}>
            <FormLabel
              style={{
                ...labelBaseStyle,
                opacity: inputValue.companyName ? 1 : 0,
                visibility: inputValue.companyName ? "visible" : "hidden",
              }}
            >
              Company Name
            </FormLabel>

            <Input
              type="text"
              name="companyName"
              placeholder="Company Name"
              value={inputValue.companyName}
              onChange={handleInputChange}
            />
          </FormControl>
          <Flex gap={2} mt={3}>
            <FormControl id="city" position="relative">
              <FormLabel
                style={{
                  ...labelBaseStyle,
                  opacity: inputValue.companyAddress.city ? 1 : 0,
                  visibility: inputValue.companyAddress.city
                    ? "visible"
                    : "hidden",
                }}
              >
                City
              </FormLabel>

              <Input
                type="text"
                name="city"
                placeholder="City"
                value={inputValue.companyAddress.city}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="state" position="relative">
              <FormLabel
                style={{
                  ...labelBaseStyle,
                  opacity: inputValue.companyAddress.state ? 1 : 0,
                  visibility: inputValue.companyAddress.state
                    ? "visible"
                    : "hidden",
                }}
              >
                State
              </FormLabel>

              <Input
                type="text"
                name="state"
                placeholder="State"
                value={inputValue.companyAddress.state}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="country" position="relative">
              <FormLabel
                style={{
                  ...labelBaseStyle,
                  opacity: inputValue.companyAddress.country ? 1 : 0,
                  visibility: inputValue.companyAddress.country
                    ? "visible"
                    : "hidden",
                }}
              >
                Country
              </FormLabel>

              <Input
                type="text"
                name="country"
                placeholder="Country"
                value={inputValue.companyAddress.country}
                onChange={handleInputChange}
              />
            </FormControl>
          </Flex>
          <Flex gap={2} mt={3}>
            <FormControl id="startingDate" isRequired>
              <FormLabel
                style={{
                  ...labelBaseStyle,
                }}
              >
                Starting Date
              </FormLabel>
              <Input
                type="date"
                name="startingDate"
                placeholder="Starting Date"
                value={inputValue.startingDate}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl id="endingDate">
              <FormLabel
                style={{
                  ...labelBaseStyle,
                }}
              >
                Ending Date
              </FormLabel>
              <Input
                type="date"
                name="endingDate"
                placeholder="Ending Date"
                value={inputValue.endingDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Flex>
          <FormControl id="role" position="relative" mt={3}>
            <FormLabel
              style={{
                ...labelBaseStyle,
                opacity: inputValue.role ? 1 : 0,
                visibility: inputValue.role ? "visible" : "hidden",
              }}
            >
              Designation
            </FormLabel>

            <Input
              type="text"
              name="role"
              placeholder="Designation"
              value={inputValue.role}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="workDetails" position="relative" mt={3}>
            <FormLabel
              style={{
                ...labelBaseStyle,
                opacity: inputValue.workDetails ? 1 : 0,
                visibility: inputValue.workDetails ? "visible" : "hidden",
              }}
            >
              Work Details
            </FormLabel>

            <Textarea
              name="workDetails"
              placeholder="Work Details"
              value={inputValue.workDetails}
              onChange={handleInputChange}
            />
          </FormControl>
          {editIndex === -1 ? (
            <Button
              colorScheme="green"
              size="xs"
              style={{
                borderRadius: "20px",
                transition: "background 0.3s ease",
              }}
              onClick={handleSubmit}
            >
              Add Work Details
            </Button>
          ) : (
            <Flex gap={1} w={"100%"}>
              <Button
                flex={1}
                onClick={handleClear}
                colorScheme="red"
                size="xs"
                style={{
                  borderRadius: "20px",
                  transition: "background 0.3s ease",
                }}
              >
                Cancel Update
              </Button>
              <Button
                flex={1}
                colorScheme="orange"
                size="xs"
                style={{
                  borderRadius: "20px",
                  transition: "background 0.3s ease",
                }}
                onClick={handleSubmit}
              >
                Update Work Details
              </Button>
            </Flex>
          )}

          <Box mt={1}>
            {workDetails.map((work, index) => (
              <Tag
                key={index}
                size="md"
                borderRadius="sm"
                variant="solid"
                colorScheme="teal"
                m={1}
                style={{ alignItems: "flex-start" }}
                w={"100%"}
              >
                <TagLabel w={"100%"}>
                  <Flex direction={"column"} py={2} w={"100%"}>
                    <Flex justify={"space-between"} align={"center"}>
                      <Text fontSize={"md"}>{work.role}</Text>
                      <Text fontSize={"xs"}>
                        {work.startingDate} - {work.endingDate}
                      </Text>
                    </Flex>
                    <Text fontSize={"md"} fontWeight="bold">
                      {work.companyName}
                    </Text>
                    <Flex gap={1}>
                      <Text fontSize={"sm"}>
                        {work.companyAddress.city}
                        {", "}
                      </Text>
                      <Text fontSize={"sm"}>
                        {work.companyAddress.state}
                        {", "}
                      </Text>
                      <Text fontSize={"sm"}>{work.companyAddress.country}</Text>
                    </Flex>

                    <Text mt={1}>
                      <span>
                        <b>Work Details: </b>
                      </span>
                      {work.workDetails}
                    </Text>
                  </Flex>
                </TagLabel>
                <IconButton
                  size="xs"
                  icon={<EditIcon />}
                  onClick={() => editWork(index)}
                  m={1}
                />
                <IconButton
                  size="xs"
                  icon={<TagCloseButton m={0} />}
                  onClick={() => removeWork(work)}
                  mt={1}
                />
              </Tag>
            ))}
          </Box>
        </VStack>
      </Box>
    </FormControl>
  );
};

const CandidateForm = ({
  isOpen,
  onClose,
  operation = "Add Candidate",
  candidate,
}) => {
  const { backendUrl } = useEnv();
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [dp, setDp] = useState(null);
  const [skills, setSkills] = useState([]);
  const [workDetails, setWorkDetails] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    dob: "",
    gender: "",
    dp: "",
    phone: "",
    whatsappNo: "",
    skills: "",
    isFresher: true,
    experience: "",
    experienceType: "NA",
    workDetails: [],
    stipend: "",
    currentCTC: "",
    expectedCTC: "",
    noticePeriod: "",
    address: {
      at: "",
      po: "",
      city: "",
      dist: "",
      state: "",
      country: "",
      pin: "",
    },
    cv: "",
    currentStatus: "On Bench",
    addedBy: "",
    otherDetails: "",
  });

  const [autoFillModal, setAutoFillModal] = useState(false);

  const [experienceValue, setExperienceValue] = useState(
    formData.experience.split(" ")[0]
  );
  // console.log(formData.experience.split(" ")[0]);
  const [experienceUnit, setExperienceUnit] = useState(
    formData.experience.split(" ")[1] || "Years"
  );
  // console.log(formData.experience.split(" ")[1] || "Years");

  const {
    addCandidate,
    updateCandidate,
    loading,
    autoFill,
    candidateAutoFillData,
    setCandidateAutoFillData,
  } = useCandidate();

  // Auto-fill form when `candidateAutoFillData` updates
  useEffect(() => {
    if (candidateAutoFillData) {
      // Update form data safely
      setFormData((prevData) => ({
        ...prevData,
        name: candidateAutoFillData.name || "",
        email: candidateAutoFillData.email || "",
        phone: candidateAutoFillData.phone || "",
      }));

      // Transform and update skills
      const transformedSkills = (candidateAutoFillData.skills || []).map(
        (skill) => ({
          skillName: skill,
          relevantExp: 0, // Default to 0 years of experience
        })
      );
      setSkills(transformedSkills);
    }
  }, [candidateAutoFillData]);

  const loadCandidate = () => {
    if (operation == "Update Candidate") {
      setFile(null);
      setDp(null);
      const updatedWorkDetails = candidate?.workDetails.map((detail) => ({
        ...detail,
        startingDate: convertISOToDate(detail.startingDate),
        endingDate: convertISOToDate(detail.endingDate),
      }));

      const candidateToBeUpdated = {
        ...candidate,
        dob: candidate?.dob ? convertISOToDate(candidate?.dob) : "",
        currentCTC: candidate?.currentCTC ? candidate?.currentCTC : "",
        expectedCTC: candidate?.expectedCTC ? candidate?.expectedCTC : "",
        stipend: candidate?.stipend ? candidate?.stipend : "",
        phone:
          candidate?.phone == Number
            ? candidate?.phone
            : candidate?.phone.replace(/[^0-9]/g, ""),
        workDetails: updatedWorkDetails,
      };
      // console.log("Candiudate to be updated", candidateToBeUpdated);
      setFormData(candidateToBeUpdated);
      setSkills(candidate?.skills);
      setWorkDetails(updatedWorkDetails);
      setExperienceValue(candidateToBeUpdated.experience.split(" ")[0]);
      setExperienceUnit(candidateToBeUpdated.experience.split(" ")[1]);
    }
  };
  useEffect(() => {
    loadCandidate();
  }, [operation]);
  // console.log("formData", formData);

  const handleFileChange = async (e) => {
    const uploadedFile = e.target.files[0];

    // Check if file is uploaded
    if (!uploadedFile) {
      console.error("No file uploaded");
      alert("No  file uploaded");
      return;
    }

    const fileExtension = uploadedFile.name.split(".").pop().toLowerCase();
    setFileType(fileExtension); // Setting file type for other logic

    // Update the form data with the uploaded file
    setFormData((prevData) => ({
      ...prevData,
      cv: uploadedFile,
    }));

    // Handle PDF and TXT preview
    if (fileExtension === "pdf") {
      setFile(URL.createObjectURL(uploadedFile));
    } else {
      alert("You can only upload .PDF files.");
    }
    setAutoFillModal(true);
  };

  // console.log(file);

  const handleDPChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFormData({ ...formData, dp: uploadedFile });
    setDp(URL.createObjectURL(uploadedFile));
  };

  const handleChange = (e) => {
    let { name, value, files } = e.target;
    const [parent, child] = name.split(".");

    if (child) {
      setFormData((prevData) => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [child]: value,
        },
      }));
    } else {
      if (value < 0) value = 0;

      if (["phone", "whatsappNo"].includes(name)) {
        if (value > 9999999999) value = Math.floor(value / 10);
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: files ? files[0] : value,
      }));
    }
  };

  const handleExperienceChange = (e) => {
    const { value } = e.target;
    setExperienceValue(value);
    setFormData({ ...formData, experience: `${value} ${experienceUnit}` });
  };

  const handleUnitChange = (e) => {
    const { value } = e.target;
    setExperienceUnit(value);
    setFormData({ ...formData, experience: `${experienceValue} ${value}` });
  };

  const handleOverallSubmit = () => {
    // Clear work details if experience type is NA
    if (formData.experienceType === "NA") {
      setFormData({ ...formData, experience: "" });
      setWorkDetails([]);
    }

    // Collect updated form data
    const updatedFormData = {
      ...formData,
      skills: skills || formData.skills,
      workDetails: workDetails || formData.workDetails,
    };

    // More validation before submission
    const errors = [];

    // Validate required fields
    if (!updatedFormData.name) {
      errors.push("Name is required.");
    }
    if (!updatedFormData.email) {
      errors.push("Email is required.");
    }
    if (!updatedFormData.phone) {
      errors.push("Phone number is required.");
    }

    // DOB should not be in the future
    const currentDate = new Date();
    if (updatedFormData.dob && new Date(updatedFormData.dob) > currentDate) {
      errors.push("Date of birth cannot be in the future.");
    }

    // Email format validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (updatedFormData.email && !emailPattern.test(updatedFormData.email)) {
      errors.push("Please enter a valid email address.");
    }

    // Phone number length validation (assuming 10 digits)
    if (updatedFormData.phone && updatedFormData.phone.length !== 10) {
      errors.push("Phone number must be 10 digits.");
    }

    // Experience validation
    if (
      updatedFormData.experience &&
      isNaN(parseInt(updatedFormData.experience.split(" ")[0]))
    ) {
      errors.push("Experience must be a valid number.");
    }

    // Validate current CTC and expected CTC if provided
    if (updatedFormData.currentCTC && updatedFormData.currentCTC < 0) {
      errors.push("Current CTC must be a positive number.");
    }
    if (updatedFormData.expectedCTC && updatedFormData.expectedCTC < 0) {
      errors.push("Expected CTC must be a positive number.");
    }

    // Validate stipend
    if (updatedFormData.stipend && updatedFormData.stipend < 0) {
      errors.push("Stipend must be a positive number.");
    }

    // Check for errors before submission
    if (errors.length > 0) {
      alert(errors.join("\n")); // Display errors to the user
      return; // Stop the submission
    }

    // Further processing
    if (formData.experienceType === "Internship") {
      updatedFormData.currentCTC = ""; // Clear CTC for internships
    }
    if (
      formData.experienceType !== "NA" &&
      formData.experienceType !== "Internship"
    ) {
      updatedFormData.stipend = ""; // Clear stipend for non-internship roles
    }

    // Update state for form data
    setFormData(updatedFormData);

    // Handle form submission
    if (operation === "Update Candidate") {
      updateCandidate(updatedFormData, candidate?._id);
    } else {
      addCandidate(updatedFormData);
      if (!loading) {
        onClose();
      }
    }
  };

  const handleAutoFillModalClose = () => {
    setAutoFillModal(false);
  };

  //Handle Autofill data function
  const handleAutofill = async (e) => {
    const data = new FormData();
    data.append("cv", formData.cv);
    try {
      await autoFill(data); // Call your autoFill function
    } catch (error) {
      console.error("Autofill failed:", error); // Handle errors
    }
  };

  const handleClose = () => {
    setCandidateAutoFillData({
      name: "",
      email: "",
      phone: "",
      skills: [],
    }); // Clear autofill data
    onClose(); // Close the modal
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={handleClose} size={"full"}>
        <ModalOverlay />
        <ModalContent height={"100vh"} overflow={"clip"}>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify={"space-between"}>
              <Heading as="h3" size="md">
                {operation}
              </Heading>
              <Flex mr={5} mb={2}>
                <Button
                  size="xs"
                  style={{
                    borderRadius: "2px",
                    marginInline: "5px",
                    transition: "background 0.3s ease",
                  }}
                  onClick={() => {
                    document.getElementById("fileInput").click();
                  }}
                >
                  {operation == "Update Candidate" ? "Update CV" : "Upload CV"}
                </Button>
                {operation == "Update Candidate" && (
                  <Button
                    colorScheme="red"
                    size="xs"
                    style={{
                      borderRadius: "20px",
                      marginInline: "5px",
                      transition: "background 0.3s ease",
                    }}
                    onClick={loadCandidate}
                  >
                    Reset
                  </Button>
                )}
                <Button
                  colorScheme="blue"
                  size="xs"
                  style={{
                    borderRadius: "20px",
                    marginInline: "5px",
                    transition: "background 0.3s ease",
                  }}
                  onClick={handleOverallSubmit}
                  isLoading={loading}
                >
                {operation}
                </Button>
              </Flex>
            </Flex>
            <Flex gap={4}>
              <VStack
                as="form"
                spacing={4}
                width="40%"
                height={"calc(100vh - 40px)"}
                overflowY={"auto"}
                pr={2}
              >
                {loading ? (
                  <Center width="100%" height="100%">
                    {" "}
                    {/* Center the spinner in both axes */}
                    <Spinner
                      size="xl" // Make spinner extra large
                      thickness="6px" // Adjust the thickness of the spinner
                      speed="0.65s" // Control spin speed
                      color="blue.500"
                    />
                  </Center>
                ) : (
                  <>
                    <FormControl id="name" isRequired>
                      <FormLabel mb={1}>Name</FormLabel>
                      <Input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Ex. John Doe"
                      />
                    </FormControl>
                    <FormControl id="email" isRequired>
                      <FormLabel mb={1}>Email</FormLabel>
                      <Input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Ex. john_doe@gmail.con"
                      />
                    </FormControl>
                    <Flex width={"100%"} gap={2}>
                      <FormControl id="phone" isRequired>
                        <FormLabel mb={1}>Phone Number</FormLabel>
                        <Input
                          type="number"
                          name="phone"
                          value={
                            typeof formData.phone === "number"
                              ? formData.phone
                              : parseInt(
                                  String(formData?.phone || "").replace(
                                    /[^0-9]/g,
                                    ""
                                  )
                                )
                          }
                          // value={formData.phone}
                          onChange={handleChange}
                          placeholder="Ex. 8249892931"
                          min={0}
                          max={9999999999}
                        />
                      </FormControl>
                      <FormControl id="whatsappNo">
                        <FormLabel mb={1}>WhatsApp Number</FormLabel>
                        <Input
                          type="number"
                          name="whatsappNo"
                          value={formData.whatsappNo}
                          onChange={handleChange}
                          placeholder="Ex. 8249892931"
                          min={0}
                          max={9999999999}
                        />
                      </FormControl>
                    </Flex>
                    <Flex width={"100%"} gap={2}>
                      <FormControl id="dob">
                        <FormLabel mb={1}>Date of Birth</FormLabel>
                        <Input
                          type="date"
                          name="dob"
                          value={formData.dob}
                          onChange={handleChange}
                          placeholder="Ex. 12/11/2000"
                        />
                      </FormControl>
                      <FormControl id="gender">
                        <FormLabel mb={1}>Gender:</FormLabel>
                        <Select
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            Choose One
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </Select>
                      </FormControl>
                    </Flex>
                    <Flex width={"100%"} gap={2} align={"flex-end"} pr={"4px"}>
                      <FormControl id="dp" flexGrow={1}>
                        <FormLabel mb={1}>Passphoto</FormLabel>
                        <Input
                          type="file"
                          name="dp"
                          onChange={handleDPChange}
                          paddingTop={"4px"}
                        />
                      </FormControl>
                      {dp && <Avatar size="lg" src={dp} />}
                      {operation == "Update Candidate" &&
                        !dp &&
                        candidate?.dp && (
                          <Avatar
                            size="lg"
                            src={`${backendUrl}${candidate?.dp}`}
                          />
                        )}
                    </Flex>
                    <SkillsInput skills={skills} setSkills={setSkills} />
                    <Flex width={"100%"} gap={2} align={"flex-end"} pr={"4px"}>
                      <FormControl id="isFresher">
                        <FormLabel mb={1}>Experience Level</FormLabel>

                        <RadioGroup
                          name="isFresher"
                          onChange={(value) =>
                            setFormData({
                              ...formData,
                              isFresher: value === "fresher",
                            })
                          }
                          value={formData.isFresher ? "fresher" : "experienced"}
                          spacing={8}
                        >
                          <Radio mr={4} value="fresher">
                            Fresher
                          </Radio>
                          <Radio value="experienced">Experienced</Radio>
                        </RadioGroup>
                      </FormControl>
                    </Flex>
                    <Flex width={"100%"} gap={2}>
                      <FormControl id="experienceType">
                        <FormLabel mb={1}>Experience Type</FormLabel>
                        <Select
                          name="experienceType"
                          value={formData.experienceType}
                          onChange={handleChange}
                          disabled={formData.isFresher}
                        >
                          <option value="Internship">Internship</option>
                          <option value="Full Time">Full Time</option>
                          <option value="Part Time">Part Time</option>
                          <option value="Contract">Contract</option>
                          <option value="NA">NA</option>
                        </Select>
                      </FormControl>
                      <FormControl id="experience">
                        <FormLabel mb={1}>Experience</FormLabel>
                        <Flex w={"100%"} gap={0.5}>
                          <Input
                            type="number"
                            name="experienceValue"
                            value={experienceValue}
                            disabled={formData.experienceType === "NA"}
                            onChange={handleExperienceChange}
                            placeholder="Ex. 3"
                          />
                          <Select
                            name="experienceUnit"
                            value={experienceUnit}
                            disabled={formData.experienceType === "NA"}
                            onChange={handleUnitChange}
                          >
                            <option value="Years">Years</option>
                            <option value="Months">Months</option>
                          </Select>
                        </Flex>
                      </FormControl>
                    </Flex>
                    {formData.experienceType != "NA" && (
                      <PreviousWorkDetailsInput
                        workDetails={workDetails}
                        setWorkDetails={setWorkDetails}
                      />
                    )}
                    {formData.experienceType == "Internship" && (
                      <FormControl id="stipend">
                        <FormLabel mb={1}>Stipend</FormLabel>
                        <Input
                          type="number"
                          name="stipend"
                          value={formData.stipend}
                          onChange={handleChange}
                          placeholder="Ex. 15000"
                        />
                      </FormControl>
                    )}{" "}
                    {formData.experienceType != "NA" &&
                      formData.experienceType != "Internship" && (
                        <FormControl id="currentCTC">
                          <FormLabel mb={1}>Current CTC</FormLabel>
                          <Input
                            type="number"
                            name="currentCTC"
                            value={formData.currentCTC}
                            onChange={handleChange}
                            placeholder="Ex. 400000"
                          />
                        </FormControl>
                      )}
                    <FormControl id="expectedCTC">
                      <FormLabel mb={1}>Expected CTC</FormLabel>
                      <Input
                        type="number"
                        name="expectedCTC"
                        value={formData.expectedCTC}
                        onChange={handleChange}
                        placeholder="Ex. 600000"
                      />
                    </FormControl>{" "}
                    {formData.experienceType != "NA" &&
                      formData.experienceType != "Internship" && (
                        <FormControl id="noticePeriod">
                          <FormLabel mb={1}>Notice Period</FormLabel>
                          <Input
                            type="text"
                            name="noticePeriod"
                            value={formData.noticePeriod}
                            onChange={handleChange}
                            placeholder="Ex. 35 Days"
                          />
                        </FormControl>
                      )}
                    <FormControl id="address">
                      <FormLabel mb={1}>Address</FormLabel>
                      <Flex gap={2}>
                        <FormControl id="address.at" position="relative">
                          <FormLabel
                            style={{
                              ...labelBaseStyle,
                              opacity: formData.address?.at ? 1 : 0,
                              visibility: formData.address?.at
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            At
                          </FormLabel>
                          <Input
                            type="text"
                            name="address.at"
                            placeholder="AT"
                            value={formData.address?.at}
                            onChange={handleChange}
                          />
                        </FormControl>
                        <FormControl id="address.po" position="relative">
                          <FormLabel
                            style={{
                              ...labelBaseStyle,
                              opacity: formData.address?.po ? 1 : 0,
                              visibility: formData.address?.po
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            PO
                          </FormLabel>
                          <Input
                            type="text"
                            name="address.po"
                            placeholder="PO"
                            value={formData.address?.po}
                            onChange={handleChange}
                          />
                        </FormControl>
                        <FormControl id="address.city" position="relative">
                          <FormLabel
                            style={{
                              ...labelBaseStyle,
                              opacity: formData.address?.city ? 1 : 0,
                              visibility: formData.address?.city
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            City
                          </FormLabel>
                          <Input
                            type="text"
                            name="address.city"
                            placeholder="City"
                            value={formData.address?.city}
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Flex>
                      <Flex gap={2} mt={3}>
                        <FormControl id="address.dist" position="relative">
                          <FormLabel
                            style={{
                              ...labelBaseStyle,
                              opacity: formData.address?.dist ? 1 : 0,
                              visibility: formData.address?.dist
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            District
                          </FormLabel>
                          <Input
                            type="text"
                            name="address.dist"
                            placeholder="District"
                            value={formData.address?.dist}
                            onChange={handleChange}
                          />
                        </FormControl>
                        <FormControl id="address.state" position="relative">
                          <FormLabel
                            style={{
                              ...labelBaseStyle,
                              opacity: formData.address?.state ? 1 : 0,
                              visibility: formData.address?.state
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            State
                          </FormLabel>
                          <Input
                            type="text"
                            name="address.state"
                            placeholder="State"
                            value={formData.address?.state}
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Flex>
                      <Flex gap={2} mt={3}>
                        <FormControl id="address.country" position="relative">
                          <FormLabel
                            style={{
                              ...labelBaseStyle,
                              opacity: formData.address?.country ? 1 : 0,
                              visibility: formData.address?.country
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            Country
                          </FormLabel>
                          <Input
                            type="text"
                            name="address.country"
                            placeholder="Country"
                            value={formData.address?.country}
                            onChange={handleChange}
                          />
                        </FormControl>
                        <FormControl id="address.pin" position="relative">
                          <FormLabel
                            style={{
                              ...labelBaseStyle,
                              opacity: formData.address?.pin ? 1 : 0,
                              visibility: formData.address?.pin
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            Pin
                          </FormLabel>
                          <Input
                            type="text"
                            name="address.pin"
                            placeholder="Pin"
                            value={formData.address?.pin}
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Flex>
                    </FormControl>
                    {operation == "Update Candidate" && (
                      <FormControl id="currentStatus">
                        <FormLabel mb={1}>Current Status</FormLabel>
                        <Select
                          name="currentStatus"
                          value={formData.currentStatus}
                          onChange={handleChange}
                        >
                          <option value="Processing">Processing</option>
                          <option value="On Bench">On Bench</option>
                          <option value="Selected">Selected</option>
                        </Select>
                      </FormControl>
                    )}
                    <FormControl id="otherDetails" mb={2}>
                      <FormLabel mb={1}>Other Details</FormLabel>
                      <Textarea
                        name="otherDetails"
                        value={formData.otherDetails}
                        onChange={handleChange}
                        placeholder="Write Other details (if any)
Ex. Hobby- Playing, Watching News"
                      />
                    </FormControl>
                  </>
                )}
              </VStack>
              <Box width="60%">
                <Input
                  id="fileInput"
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  display="none"
                  placeholder="Add file"
                />
                {operation == "Update Candidate" && !file && candidate?.cv && (
                  <iframe
                    src={`${backendUrl}${candidate?.cv}`}
                    width="100%"
                    height="100%"
                    title="CV Preview"
                  />
                )}
                {file && fileType === "pdf" && (
                  <iframe
                    src={file}
                    width="100%"
                    height="100%"
                    title="CV Preview"
                  />
                )}

                {/* {file && fileType === "txt" && (
                  <iframe
                    src={file}
                    width="100%"
                    height="500px"
                    title="CV Preview"
                    style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        height: "auto", // Remove any constraints on height
                        maxHeight: "80vh", // Set a reasonable maxHeight to limit the view
                        overflowY: "auto", // Enable scrolling if content overflows
                        whiteSpace: "pre-wrap", // Maintain spacing and formatting
                    
                      }}
                  />
                )}

                {file &&
                  (fileType === "doc" || fileType === "docx") &&
                  content && (
                    <div
                      dangerouslySetInnerHTML={{ __html: content }}
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        height: "auto", // Remove any constraints on height
                        maxHeight: "90vh", // Set a reasonable maxHeight to limit the view
                        overflowY: "auto", // Enable scrolling if content overflows
                        whiteSpace: "pre-wrap", // Maintain spacing and formatting
                        backgroundColor: "white", // Set background color to white
                        color: "black", // Set text color to black
                      }}
                    />
                  )} */}

                {!file && (
                  <Flex
                    padding="6"
                    width={"100%"}
                    height="100%"
                    justify={"center"}
                    align={"center"}
                  >
                    Add CV to preview here
                  </Flex>
                )}
              </Box>
            </Flex>
            {autoFillModal && (
              <AutoFillModal
                operation={"autofill"}
                isOpen={autoFillModal}
                onClose={handleAutoFillModalClose}
                onAutofill={handleAutofill}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CandidateForm;
