import {
  Box,
  Button,
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaFileExcel, FaFilter, FaSearch, FaTimes } from "react-icons/fa";
import { ImUpload2 } from "react-icons/im";

import CandidateForm from "./CandidateForm";
import { useCandidate } from "../../../context/CandidateContext";
import { useParams } from "react-router-dom";
import CandidateFilterModal from "./CandidateFilterModal";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import CandidateDataModal from "./CandidateDataModal";

const CandidateHeader = ({ initialPath }) => {
  const { filteredCandidates, setFilteredCandidates,setFilterItem } = useCandidate();
  const [isCandidateFormModalOpen, setIsCandidateFormModalOpen] =
    useState(false);
  const [isCandidateFilterModalOpen, setIsCandidateFilterModalOpen] =
    useState(false);
  const [uploadCandidateModalOpen, setUploadCandidateModalOpen] =
    useState(false);
  const handleCandidateForm = () => {
    setIsCandidateFormModalOpen(true);
  };
  const handleCandidateFile = () => {
    setUploadCandidateModalOpen(true);
  };
  const handleCandidateFilter = () => {
    setIsCandidateFilterModalOpen(true);
  };

  const handleCandidateFormCloseModal = () => {
    setIsCandidateFormModalOpen(false);
  };
  const handleCandidateFilterCloseModal = () => {
    setIsCandidateFilterModalOpen(false);
  };
  const handleCandidateFileCloseModal = () => {
    setUploadCandidateModalOpen(false);
  };

  const handleClearFilter = () => {
    setFilteredCandidates([]);
    setFilterItem([])
  };

  return (
    <>
      <Flex
        direction={"row-reverse"}
        alignItems={"center"}
        position={"absolute"}
        top={"1px"}
        right="0"
      >
        <Tooltip
          hasArrow
          label={
            <Text fontSize="xs" fontWeight="bold">
              Upload candidates through Excel
            </Text>
          }
          bg="gray.300"
          color="black"
          placement="top"
        >
          <Button
            bg={tagColor("green").bg}
            color={tagColor("green").clr}
            size="xs"
            style={{
              borderRadius: "20px",
              background: "#1F2937",
              color: "white",
              marginInline: "10px",
              transition: "background 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.target.style.background = "#3B4A5A";
            }}
            onMouseLeave={(e) => {
              e.target.style.background = "#1F2937";
            }}
            onClick={() => {
              handleCandidateFile();
            }}
          >
            <ImUpload2
              size={22}
              style={{
                color: "lightgreen",
                marginInline: "10px",
                transition: "color 0.3s ease",
              }}
            />
          </Button>
        </Tooltip>

        <Button
          colorScheme="gray"
          size="xs"
          style={{
            borderRadius: "20px",
            background: "#1F2937",
            color: "white",
            marginInline: "10px",
            transition: "background 0.3s ease",
          }}
          onMouseEnter={(e) => {
            e.target.style.background = "#3B4A5A";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "#1F2937";
          }}
          onClick={() => {
            handleCandidateForm();
          }}
        >
          Add Candidate
        </Button>
        <Button
          bg={
            filteredCandidates?.length > 0
              ? tagColor("red").bg // Use red background if filtered candidates exist
              : tagColor("purple").bg // Default to purple background
          }
          color={tagColor("purple").clr}
          px={2}
          borderRadius={20}
          height={"25px"}
          fontWeight="bold"
          fontSize={"sm"}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          onMouseEnter={(e) => {
            e.target.style.background = "#3B4A5A";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "#1F2937";
          }}
          onClick={() => {
            handleCandidateFilter();
          }}
        >
          Filter
          {filteredCandidates?.length > 0 ? (
            <Box
              height="25px"
              ml={0}
              width="fit-content" // Ensures the button adjusts to content size
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding="0" // Optional: Ensures no extra padding around the icon
              bg="transparent" // Optional: Remove background for a cleaner look
              _hover={{ bg: "transparent" }} // No hover background change
              onClick={(e) => {
                e.stopPropagation(); // Prevent event propagation to parent
                handleClearFilter(e); // Handle child click event
              }}
            >
              <FaTimes size={20}  bg="transparent"  color="red" />
            </Box>
          ) : (
            <FaFilter />
          )}
        </Button>
      </Flex>
      {isCandidateFilterModalOpen && (
        <CandidateFilterModal
          operation={"Filter"}
          isOpen={isCandidateFilterModalOpen}
          onClose={handleCandidateFilterCloseModal}
          initialPath={initialPath}
        />
      )}
      {isCandidateFormModalOpen && (
        <CandidateForm
          operation={"Add Candidate"}
          isOpen={isCandidateFormModalOpen}
          onClose={handleCandidateFormCloseModal}
        />
      )}
      {uploadCandidateModalOpen && (
        <CandidateDataModal
          operation={"UploadFile"}
          isOpen={uploadCandidateModalOpen}
          onClose={handleCandidateFileCloseModal}
        />
      )}
    </>
  );
};

export default CandidateHeader;
