import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Icon,
} from "@chakra-ui/react";

import JDHeader from "./JDHeader";
import JobDescription from "./JobDescription";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useJD } from "../../../context/JDContext";
import DashboardJobDescription from "./DashboardJobDescription";
import { ImStatsBars } from "react-icons/im";

const JdContainer = () => {
  const {
    fetchAllJD,
    loading,
    allJDs,
    hasMoreJDs,
    jDsPageNo,
    filteredJDs,
    setFilteredJDs,
    hasMoreFilteredJDs,
    filteredJDsPageNo,
    fetchFilteredJDs
  } = useJD();
  const { status } = useParams();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const initialPath = pathname.split("/")[1];
  const { jdList } = state || [];

  const [tabIndex, setTabIndex] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [showDashJDTab, setShowDashJDTab] = useState(false);

  const tabConfig = [
    { status: "All", key: "all", color: "" },
    { status: "Closed", key: "closed", color: "green" },
    { status: "Active", key: "active", color: "blue" },
    { status: "OnHold", key: "onHold", color: "orange" },
    { status: "Cancelled", key: "cancelled", color: "red" },
  ];

  useEffect(() => {
    const currentTab =
      tabConfig.find((tab) => tab.status === status) || tabConfig[2];
    fetchAllJD(currentTab.key, 1, 30);
    setTabIndex(tabConfig.indexOf(currentTab));

    if (status === "dashboardData") {
      setShowDashJDTab(true);
    } else {
      setShowDashJDTab(false);
    }
  }, [status]);

  const handleTabsChange = (index) => {
    setShowDashJDTab(false);
    setTabIndex(index);
    setFilteredJDs({ data: [] }); // Clear the filtered JDs
    const tabMapping = ["All", "Closed", "Active", "OnHold", "Cancelled"];
    navigate(`/${initialPath}/JobDescriptions/${tabMapping[index]}`);
  };

  // Update useEffect for resetting sortedData
  useEffect(() => {
    if (filteredJDs?.length > 0) {
      // Use filtered data if available
      setSortedData(filteredJDs);
    } else {
      // Use default data for the active tab
      setSortedData(allJDs[tabConfig[tabIndex]?.key] || []);
    }
  }, [filteredJDs, allJDs, tabIndex]);

  const fetchJDS = filteredJDs?.length > 0
  ? fetchFilteredJDs
  : fetchAllJD;

  return (
    <Tabs
      isLazy
      variant="enclosed"
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <JDHeader initialPath={initialPath} />
      <TabList>
        {tabConfig.map((tab) => (
          <Tab key={tab.key}>
            <Text
              bg={tagColor(tab.color).bg}
              color={tagColor(tab.color).clr}
              px={2}
              borderRadius={20}
              fontWeight="bold"
              fontSize="sm"
            >
              {tab.status}
            </Text>
          </Tab>
        ))}
        {showDashJDTab && (
          <Tab>
            <Icon as={ImStatsBars} fontSize="24px" />
          </Tab>
        )}
      </TabList>

      <TabPanels>
        {tabConfig.map((tab) => (
          <TabPanel key={tab.key}>
            <JobDescription
              data={sortedData} // Updated to use sortedData
              fetchAllJD={fetchJDS}
              hasMore={
                filteredJDs?.length > 0
                  ? hasMoreFilteredJDs
                  : hasMoreJDs[tab.key]
              } // Use appropriate hasMore flag
              pageNo={
                filteredJDs?.length > 0
                  ? filteredJDsPageNo
                  : jDsPageNo[tab.key]
              } // Use appropriate page number
              status={tab.key}
              loading={loading}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default JdContainer;
