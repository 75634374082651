import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Checkbox,
  CheckboxGroup,
  Stack,
  Input,
  Button,
  Box,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { useCandidate } from "../../../context/CandidateContext";
import { useUser } from "../../../context/UserContext";

const CandidateFilterModal = ({
  isOpen,
  onClose,
  operation = "Filter",
  initialPath = "",
}) => {
  const { fetchAllUsers, allEmployees } = useUser();
  const { setFilteredCandidates, fetchFilteredCandidates,setLoading,
    setFilterItem } = useCandidate();
  const [selectedExperience, setSelectedExperience] = useState({
    min: "",
    max: "",
  });
  const [selectedCtc, setSelectedCtc] = useState({
    min: "",
    max: "",
  });
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [address, setAddress] = useState({
    city: "",
    state: "",
    country: "",
  });
  const [searchRecruiter, setSearchRecruiter] = useState("");
  const [selectedRecruiters, setSelectedRecruiters] = useState([]); // Store recruiter IDs
  const [filteredRecruiters, setFilteredRecruiters] = useState([]);
  const [allRecruiters, setAllRecruiters] = useState([]);

  // Fetch active recruiters when on admin_dashboard path
  useEffect(() => {
    if (initialPath === "admin_dashboard") {
      fetchAllUsers("activeRecruiters", 1, 30);
    }
  }, []);

  // Update recruiters when allEmployees changes
  useEffect(() => {
    if (allEmployees?.activeRecruiters) {
      setAllRecruiters(allEmployees.activeRecruiters);
    } else {
      setAllRecruiters([]);
    }
  }, [allEmployees]);

  // Handle recruiter search term changes
  const handleRecruiterSearchChange = (e) => {
    const value = e.target.value;
    setSearchRecruiter(value);

    // Filter recruiters based on search term
    if (value) {
      setFilteredRecruiters(
        allRecruiters.filter((recruiter) =>
          recruiter.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredRecruiters([]);
    }
  };

  // Handle adding a custom recruiter on Enter key press
  const handleRecruiterKeyPress = (e) => {
    if (e.key === "Enter" && searchRecruiter) {
      const recruiter = allRecruiters.find(
        (recruiter) => recruiter.name === searchRecruiter
      );
      if (recruiter && !selectedRecruiters.includes(recruiter._id)) {
        setSelectedRecruiters([...selectedRecruiters, recruiter._id]);
      }
      setSearchRecruiter("");
      setFilteredRecruiters([]);
    }
  };

  // Handle recruiter selection from suggested recruiters
  const handleRecruiterSelect = (recruiter) => {
    if (!selectedRecruiters.includes(recruiter._id)) {
      setSelectedRecruiters([...selectedRecruiters, recruiter._id]);
    }
    setSearchRecruiter("");
    setFilteredRecruiters([]);
  };

  // Handle recruiter selection from checkboxes
  const handleRecruiterChange = (selected) => {
    setSelectedRecruiters(selected);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSkills, setFilteredSkills] = useState([]);

  const [isClearing, setIsClearing] = useState(false);

  // Static skills array
  const [skillsArray, setSkillsArray] = useState([
    // Technical Skills
    "JavaScript",
    "Python",
    "Java",
    "C++",
    "C#",
    "Ruby",
    "Go",
    "Swift",
    "Kotlin",
    "HTML",
    "CSS",
    "TypeScript",
    "React",
    "Angular",
    "Vue",
    "Node.js",
    "Django",
    "Flask",
    "Ruby on Rails",
    "ASP.NET",
    "Spring Boot",
    "SQL",
    "NoSQL",
    "MongoDB",
    "PostgreSQL",
    "MySQL",
    "Oracle Database",
    "Firebase",
    "AWS",
    "Azure",
    "Google Cloud",
    "Docker",
    "Kubernetes",
    "Git",
    "CI/CD",
    "Jenkins",
    "Terraform",
    "Linux",
    "Unix",
    "Machine Learning",
    "Deep Learning",
    "Data Analysis",
    "Data Science",
    "Artificial Intelligence",
    "NLP",
    "Computer Vision",
    "Data Engineering",
    "Big Data",
    "Hadoop",
    "Spark",
    "Scala",
    "R Programming",
    "MATLAB",
    "SAS",
    "Tableau",
    "Power BI",
    "Excel",
    "Blockchain",
    "Cryptography",
    "Cybersecurity",
    "Penetration Testing",
    "Network Security",
    "Cloud Security",
    "Digital Forensics",
    "IoT",
    "Augmented Reality",
    "Virtual Reality",
    "Game Development",
    "Unreal Engine",
    "Unity",
    "Embedded Systems",
    "Microcontrollers",
    "Arduino",
    "Raspberry Pi",

    // Non-Technical Skills
    "Communication",
    "Teamwork",
    "Leadership",
    "Time Management",
    "Problem Solving",
    "Creativity",
    "Adaptability",
    "Critical Thinking",
    "Conflict Resolution",
    "Project Management",
    "Negotiation",
    "Decision Making",
    "Emotional Intelligence",
    "Interpersonal Skills",
    "Presentation Skills",
    "Public Speaking",
    "Customer Service",
    "Sales",
    "Marketing",
    "Brand Management",
    "Content Creation",
    "Social Media Marketing",
    "SEO",
    "Digital Marketing",
    "Copywriting",
    "Content Writing",
    "Blogging",
    "Editing",
    "Proofreading",
    "Event Planning",
    "Strategic Planning",
    "Market Research",
    "CRM",
    "Product Management",
    "Business Analysis",
    "Data Entry",
    "Administrative Skills",
    "Organizational Skills",
    "Self-Motivation",
    "Work Ethic",

    // IT and Non-IT Skills
    "Technical Support",
    "Help Desk",
    "System Administration",
    "Network Administration",
    "Database Administration",
    "ITIL",
    "ERP",
    "SAP",
    "Salesforce",
    "IT Compliance",
    "DevOps",
    "Automation",
    "Robotic Process Automation (RPA)",
    "Software Testing",
    "Manual Testing",
    "Automation Testing",
    "Quality Assurance",
    "Agile Methodologies",
    "Scrum",
    "Kanban",
    "JIRA",
    "Confluence",
    "IT Security",
    "Incident Management",
    "Disaster Recovery",
    "Risk Management",
    "Finance",
    "Accounting",
    "Taxation",
    "Auditing",
    "Financial Analysis",
    "Investment Management",
    "Trading",
    "Stock Market Analysis",
    "Wealth Management",
    "Real Estate Investment",
    "Financial Modeling",
    "Forecasting",

    // Mechanical and Engineering Skills
    "Mechanical Engineering",
    "Electrical Engineering",
    "Civil Engineering",
    "Chemical Engineering",
    "Biomedical Engineering",
    "Aerospace Engineering",
    "Automotive Engineering",
    "Structural Engineering",
    "Process Engineering",
    "CAD",
    "AutoCAD",
    "SolidWorks",
    "Fusion 360",
    "3D Printing",
    "CNC Programming",
    "Manufacturing",
    "Quality Control",
    "Welding",
    "Material Science",
    "Thermodynamics",
    "HVAC",
    "Pneumatics",
    "Hydraulics",
    "Machining",
    "Blueprint Reading",
    "Tooling",
    "Maintenance Engineering",
    "Electronics",
    "Circuit Design",
    "PLC Programming",
    "Instrumentation",
    "Control Systems",
    "Mechatronics",

    // Trading and Finance Skills
    "Stock Trading",
    "Day Trading",
    "Options Trading",
    "Forex Trading",
    "Commodity Trading",
    "Risk Analysis",
    "Portfolio Management",
    "Financial Planning",
    "Hedge Funds",
    "Derivatives",
    "Cryptocurrency Trading",
    "Blockchain Analysis",
    "Technical Analysis",
    "Fundamental Analysis",
    "Quantitative Analysis",
    "Valuation",
    "Corporate Finance",
    "Equity Research",
    "Venture Capital",
    "Private Equity",
    "Investment Banking",

    // Additional Miscellaneous Skills
    "Photography",
    "Videography",
    "Graphic Design",
    "UI/UX Design",
    "Illustrator",
    "Photoshop",
    "Sketch",
    "Figma",
    "Adobe XD",
    "After Effects",
    "3D Modeling",
    "Interior Design",
    "Fashion Design",
    "Cooking",
    "Baking",
    "Bartending",
    "Gardening",
    "Landscaping",
    "Woodworking",
    "Carpentry",
    "Plumbing",
    "Electrician",
    "Hair Styling",
    "Makeup Artistry",
    "Massage Therapy",
    "Yoga Instruction",
    "Fitness Training",
    "Nutrition",
    "First Aid",
    "Public Relations",
    "Fundraising",
    "Teaching",
    "Tutoring",
    "Language Translation",
    "Sign Language",
    "Foreign Language Proficiency",
    "Customer Relationship Management",
  ]);

  // Handle input changes
  const handleExperienceChange = (event, type) => {
    const value = event.target.value;
    setSelectedExperience((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };
  const handleCtcChange = (event, type) => {
    const value = event.target.value;
    setSelectedCtc((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };
  const handleSkillChange = (skills) => setSelectedSkills(skills);
  const handleAddressChange = (e) =>
    setAddress({ ...address, [e.target.name]: e.target.value });

  // Filter skills based on search term
  useEffect(() => {
    if (searchTerm) {
      setFilteredSkills(
        skillsArray.filter(
          (skill) =>
            skill.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !selectedSkills.includes(skill)
        )
      );
    } else {
      setFilteredSkills([]);
    }
  }, [searchTerm, selectedSkills]);

  // Add selected skill from suggestion
  const handleSkillSelect = (skill) => {
    setSelectedSkills((prevSkills) => [...prevSkills, skill]);
    setSearchTerm(""); // Clear search term after selection
  };

  // Check if any filters are applied
  const isFilterApplied =
    selectedExperience ||
    selectedCtc ||
    selectedSkills.length > 0 ||
    Object.values(address).some((field) => field !== "") ||
    selectedRecruiters.length > 0;

  const applyFilters = () => {
    const filtersArray = [
      { type: "experience", value: selectedExperience },
      { type: "ctc", value: selectedCtc },
      { type: "skills", value: selectedSkills },
      { type: "address", value: address },
      { type: "recruiter", value: selectedRecruiters },
    ];
    setFilterItem([])
    setLoading(true)
    fetchFilteredCandidates(filtersArray);
    setFilterItem(filtersArray)
    onClose();
  };

  const clearFilters = () => {
    setSelectedExperience("");
    setSelectedCtc("");
    setSelectedSkills([]);
    setAddress({ city: "", state: "", country: "" });
    setSelectedRecruiters([]);
    setFilterItem([])
    setIsClearing(true);
  };

  // Handle pressing Enter in the search input
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchTerm) {
      // Check if the skill already exists in the selectedSkills or skillsArray
      if (
        !skillsArray.includes(searchTerm) &&
        !selectedSkills.includes(searchTerm)
      ) {
        // Add the new skill to the skillsArray dynamically
        setSkillsArray((prevSkills) => [...prevSkills, searchTerm]);
      }
      // Add the skill to selectedSkills
      handleSkillSelect(searchTerm);
    }
  };

  useEffect(() => {
    if (isClearing) {
      setFilteredCandidates([]);
      setFilterItem([])
      setIsClearing(false);
      onClose();
    }
  }, [isClearing, setFilteredCandidates, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent
        position="absolute" // Set the positioning type
        top="0%" // Move it down from the top
        right="2%" // Move it right from the left
        transform="translate(-25%, 0)" // Adjust positioning if needed
      >
        <ModalHeader>{operation} Options</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={1} mb={2}>
            {/* Experience Section */}
            <Flex gap={2}>
              <Box width="48%">
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Experience (Years)
                </Text>
                <Flex gap={2} mb={2}>
                  <Input
                    placeholder="Min (e.g., 0)"
                    value={selectedExperience.min}
                    onChange={(e) => handleExperienceChange(e, "min")}
                  />
                  <Input
                    placeholder="Max (e.g., 5)"
                    value={selectedExperience.max}
                    onChange={(e) => handleExperienceChange(e, "max")}
                  />
                </Flex>
              </Box>
              <Box width="48%">
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  CTC
                </Text>
                <Flex gap={2} mb={4}>
                  <Input
                    placeholder="Min (e.g., 0)"
                    value={selectedCtc.min}
                    onChange={(e) => handleCtcChange(e, "min")}
                  />
                  <Input
                    placeholder="Max (e.g., 5)"
                    value={selectedCtc.max}
                    onChange={(e) => handleCtcChange(e, "max")}
                  />
                </Flex>
              </Box>
            </Flex>

            {/* Skills Section */}
            <Text fontSize="lg" fontWeight="bold">
              Skills
            </Text>
            <Input
              placeholder="Search or add a skill"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress} // Handle Enter key for adding custom skill
              mb={1}
            />
            <CheckboxGroup value={selectedSkills} onChange={handleSkillChange}>
              <Stack spacing={2}>
                {selectedSkills.map((skill) => (
                  <Checkbox key={skill} value={skill}>
                    {skill}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>

            {/* Suggested Skills */}
            {filteredSkills.length > 0 && (
              <Box
                mt={2}
                border="1px solid #ccc"
                borderRadius="md"
                p={2}
                maxHeight="200px" // Adjust the max height as needed
                overflowY="auto" // Enables vertical scrolling
              >
                <Text fontSize="sm" fontWeight="bold" mb={2}>
                  Suggested Skills
                </Text>
                <Stack spacing={1}>
                  {filteredSkills.map((skill) => (
                    <Button
                      key={skill}
                      variant="ghost"
                      onClick={() => handleSkillSelect(skill)}
                      size="sm"
                    >
                      {skill}
                    </Button>
                  ))}
                </Stack>
              </Box>
            )}

            {/* Address Section */}
            <Text fontSize="lg" fontWeight="bold">
              Address
            </Text>
            <Input
              placeholder="City"
              name="city"
              value={address.city}
              onChange={handleAddressChange}
            />
            <Input
              placeholder="State"
              name="state"
              value={address.state}
              onChange={handleAddressChange}
            />
            <Input
              placeholder="Country"
              name="country"
              value={address.country}
              onChange={handleAddressChange}
            />
          </Stack>
          {initialPath === "admin_dashboard" && (
            <Box>
              {/* Recruiters Section */}
              <Text fontSize="lg" fontWeight="bold">
                Recruiters
              </Text>
              <Input
                placeholder="Search or add a recruiter"
                value={searchRecruiter}
                onChange={handleRecruiterSearchChange}
                onKeyPress={handleRecruiterKeyPress}
                mb={3}
              />
              <CheckboxGroup
                value={selectedRecruiters}
                onChange={handleRecruiterChange}
              >
                <Stack spacing={2}>
                  {selectedRecruiters.map((recruiterId) => {
                    // Find recruiter name from ID
                    const recruiter = allRecruiters.find(
                      (recruiter) => recruiter._id === recruiterId
                    );
                    return (
                      <Checkbox key={recruiterId} value={recruiterId}>
                        {recruiter ? recruiter.name : "Unknown Recruiter"}
                      </Checkbox>
                    );
                  })}
                </Stack>
              </CheckboxGroup>

              {/* Suggested Recruiters */}
              {filteredRecruiters.length > 0 && (
                <Box
                  mt={2}
                  border="1px solid #ccc"
                  borderRadius="md"
                  p={2}
                  maxHeight="200px" // Adjust max height as needed
                  overflowY="auto"
                >
                  <Text fontSize="sm" fontWeight="bold" mb={2}>
                    Suggested Recruiters
                  </Text>
                  <Stack spacing={1}>
                    {filteredRecruiters.map((recruiter) => (
                      <Button
                        key={recruiter._id}
                        variant="ghost"
                        onClick={() => handleRecruiterSelect(recruiter)}
                        size="sm"
                      >
                        {recruiter.name}
                      </Button>
                    ))}
                  </Stack>
                </Box>
              )}
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={applyFilters}
            isDisabled={!isFilterApplied}
          >
            Apply Filters
          </Button>

          <Button variant="ghost" colorScheme="red" onClick={clearFilters}>
            Clear Filters
          </Button>

          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CandidateFilterModal;
