import React, { useState, useEffect } from "react";
import { useClient } from "../../../context/ClientContext";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Text,
  Box,
  Tooltip,
  Flex,
  Skeleton,
  MenuDivider,
  Avatar,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";

import { MdMenu } from "react-icons/md";
import { useEnv } from "../../../context/EnvContext";
import { printSkills } from "../../../Utils/GlobalFunctions/PrintSkills";
import { percentageToColor } from "../../../Utils/GlobalFunctions/ColorAccPer";
import { GiNetworkBars } from "react-icons/gi";
import { formatToShortDate } from "../../../Utils/GlobalFunctions/FormatDate";
import { GrMore } from "react-icons/gr";

const ClientJds = ({ ClientId }) => {
  const { fetchClientJDs, fetchClientApplications, clientJds } = useClient();
  const { backendUrl } = useEnv();
  const [sortedData, setSortedData] = useState([]);
  
  // Sync sortedData with clientJds
  useEffect(() => {
    if (clientJds && clientJds.length > 0) {
      setSortedData(clientJds);
    }
  }, [clientJds]);
  
  // Fetch JDs for the client when ClientId changes
  useEffect(() => {
    if (ClientId) {
      fetchClientJDs(ClientId);
    }
  }, []);
  
  // Render message if no JDs are found
  if (!clientJds || clientJds.length === 0) {
    return <div>No JDs Found</div>;
  }

  return (
    <div
      style={{
        maxHeight: "79vh",
        overflow: "auto",
        position: "relative",
      }}
    >
      <TableContainer
        style={{ position: "relative" }}
        overflowY={sortedData.length < 5 ? "auto" : "clip"}
        overflowX={sortedData.length < 5 ? "clip" : "clip"}
      >
        <Table size="sm">
          <Thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#05080D",
              zIndex: 1,
            }}
          >
            <Tr >
              <Th  fontSize={"small"}style={{ color: "white" }} p={3}>
                Sl
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} pr={0}>
                <Tooltip
                  hasArrow
                  label={
                    <Flex align={"center"} direction={"column"}>
                      <Text>Job Description Id</Text>
                      <Text fontSize={"xs"} color={"#2b2a2a"}>
                        Click to view JD Details
                      </Text>
                    </Flex>
                  }
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    JD Id
                  </Flex>
                </Tooltip>
              </Th>

              <Th  fontSize={"small"}style={{ color: "white" }} pr={0}>
                <Tooltip
                  hasArrow
                  label={
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Text fontSize="xs">Click to get Related Job Titles</Text>
                    </div>
                  }
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    Job Title
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} pr={0}>
                <Tooltip
                  hasArrow
                  label={
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Overall Experience <br />{" "}
                    </div>
                  }
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    Exp.
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }}>
                <Tooltip
                  hasArrow
                  label={
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Text fontSize="xs">
                        Click to get the Skills List with Relevant Experience
                        and Overall Experience
                      </Text>
                    </div>
                  }
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    Required Skills
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} px={1}>
                <Tooltip
                  hasArrow
                  label="No. of Open Positions"
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    Op
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} px={1}>
                <Tooltip
                  hasArrow
                  label="No. of Closed Positions"
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    Cl
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} px={1}>
                <Tooltip
                  hasArrow
                  label="No. of Remaining Positions"
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    Re
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} px={1}>
                <Tooltip
                  hasArrow
                  label="Total Process application"
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    TPA
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} px={1}>
                <Tooltip
                  hasArrow
                  label="Total selected application"
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    TSA
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} pr={0}>
                <Tooltip
                  hasArrow
                  label="JD Added on"
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    Added
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} pr={0}>
                <Tooltip
                  hasArrow
                  label="Closing Date"
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    Closing
                  </Flex>
                </Tooltip>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} pr={0}>
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  Salary
                </Flex>
              </Th>
              <Th  fontSize={"small"}style={{ color: "white" }} pr={0}>
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  Status
                </Flex>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {sortedData &&
              sortedData.map((jd, index) => (
                <Tr key={index}>
                  <Td p={4}>{index + 1}</Td>
                  <Td style={{ cursor: "pointer" }} pr={0}>
                    {jd?.jobId}
                  </Td>

                  <Td pr={0}>
                    {!jd?.similarTags ? (
                      <Text
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "150px",
                        }}
                      >
                        {" "}
                        {jd?.jobTitle}
                      </Text>
                    ) : (
                      <Popover>
                        <PopoverTrigger>
                          <Text
                            cursor={"pointer"}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: "150px",
                            }}
                          >
                            {jd?.jobTitle}
                          </Text>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>Similar Job Titles</PopoverHeader>
                          <PopoverBody
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                            }}
                          >
                            {jd?.jobTitle},
                            {jd?.similarTags.map((tag, index) => (
                              <span key={index}>
                                {tag}
                                {index < jd?.similarTags.length - 1 && ", "}
                              </span>
                            ))}
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                  </Td>
                  <Td pr={0}>{jd?.experience} Years</Td>
                  <Td pr={0}>
                    <Popover>
                      <PopoverTrigger>
                        <Text cursor={"pointer"}>
                          {printSkills(jd?.skills, 20)}
                        </Text>
                      </PopoverTrigger>
                      <PopoverContent w={"200px"}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Skills</PopoverHeader>
                        <PopoverBody
                          style={{
                            textWrap: "wrap",
                            lineHeight: "normal",
                          }}
                        >
                          {jd?.skills?.map((skill, index) => (
                            <Text
                              key={index}
                            >{`${skill.skillName} - ${skill.relevantExp} Years`}</Text>
                          ))}
                          {jd?.optionalSkills?.length > 0 && (
                            <>
                              <hr
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "4px",
                                }}
                              />
                              <Flex gap={1} wrap={"wrap"}>
                                <Text fontWeight={"bold"}>
                                  Optional Skills -
                                </Text>
                                {jd?.optionalSkills?.map((skill, index) => (
                                  <Text key={index}>{`${skill}, `}</Text>
                                ))}
                              </Flex>
                            </>
                          )}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Td>
                  <Td pr={0}>{jd?.numberOfOpenings}</Td>
                  <Td pr={0}>{jd?.noOfClosings}</Td>
                  <Td pr={0}>
                    {(jd?.numberOfOpenings ?? 0) - (jd?.noOfClosings ?? 0) || 0}
                  </Td>
                  <Td pr={0}>{(jd?.allApplications.length ?? 0) || 0}</Td>
                  <Td pr={0}>{(jd?.selectedApplications.length ?? 0) || 0}</Td>

                  <Td pr={0}>{formatToShortDate(jd?.createdAt)}</Td>
                  <Td pr={0}>{formatToShortDate(jd?.closingDate)}</Td>
                  <Td pr={0}>₹ {jd?.salaryOffering}</Td>
                  <Td pr={0}>
                    {jd?.status === "Closed" && (
                      <Badge variant="subtle" colorScheme="green">
                        Closed
                      </Badge>
                    )}
                    {jd?.status === "Active" && (
                      <Badge variant="subtle" colorScheme="blue">
                        Active
                      </Badge>
                    )}
                    {jd?.status === "On Hold" && (
                      <Badge variant="subtle" colorScheme="orange">
                        On Hold
                      </Badge>
                    )}
                    {jd?.status === "Cancelled" && (
                      <Badge variant="subtle" colorScheme="red">
                        Cancelled
                      </Badge>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ClientJds;
