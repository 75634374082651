import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaFilter, FaSearch, FaTimes } from "react-icons/fa";
import JDForm from "./JDForm";
import JdFilterModal from "./JdFilterModal";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useJD } from "../../../context/JDContext";

const JDHeader = ({ filterData, initialPath }) => {
  const [isJDFormModalOpen, setIsJDFormModalOpen] = useState(false);
  const [isJDFilterModalOpen, setIsJDFilterModalOpen] = useState(false);
  const { filteredJDs, setFilteredJDs,setFilterJdItem } = useJD();

  const handleJdFilter = () => {
    setIsJDFilterModalOpen(true);
  };
  const handleJDFilterCloseModal = () => {
    setIsJDFilterModalOpen(false);
  };

  const handleJDForm = () => {
    setIsJDFormModalOpen(true);
  };

  const handleJDFormCloseModal = () => {
    setIsJDFormModalOpen(false);
  };

  const handleClearFilter = () => {
    setFilteredJDs([]);
    setFilterJdItem([])
  };

  return (
    <>
      <Flex
        direction={"row-reverse"}
        alignItems={"center"}
        position={"absolute"}
        top={"1px"}
        right="0"
      >
        {initialPath == "bd_dashboard" && (
          <Button
            colorScheme="gray"
            size="xs"
            style={{
              borderRadius: "20px",
              background: "#1F2937",
              color: "white",
              marginInline: "10px",
              transition: "background 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.target.style.background = "#3B4A5A";
            }}
            onMouseLeave={(e) => {
              e.target.style.background = "#1F2937";
            }}
            onClick={() => {
              handleJDForm();
            }}
          >
            Add New JD
          </Button>
        )}
        <Button
          bg={tagColor("purple").bg}
          color={tagColor("purple").clr}
          px={2}
          borderRadius={20}
          height={"25px"}
          fontWeight="bold"
          fontSize={"sm"}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          onMouseEnter={(e) => {
            e.target.style.background = "#3B4A5A";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "#1F2937";
          }}
          onClick={() => {
            handleJdFilter();
          }}
        >
          Filter
          {filteredJDs?.length > 0 ? (
            <Box
              height="25px"
              ml={0}
              width="fit-content" // Ensures the button adjusts to content size
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding="0" // Optional: Ensures no extra padding around the icon
              bg="transparent" // Optional: Remove background for a cleaner look
              _hover={{ bg: "transparent" }} // No hover background change
              onClick={(e) => {
                e.stopPropagation(); // Prevent event propagation to parent
                handleClearFilter(e); // Handle child click event
              }}
            >
              <FaTimes size={20}  bg="transparent"  color="red" />
            </Box>
          ) : (
            <FaFilter />
          )}
        </Button>
      </Flex>
      {initialPath == "bd_dashboard" && isJDFormModalOpen && (
        <JDForm
          operation={"Add JD"}
          isOpen={isJDFormModalOpen}
          onClose={handleJDFormCloseModal}
        />
      )}
      {isJDFilterModalOpen && (
        <JdFilterModal
          operation={"Filter JD"}
          isOpen={isJDFilterModalOpen}
          onClose={handleJDFilterCloseModal}
          initialPath={initialPath}
        />
      )}
    </>
  );
};

export default JDHeader;
