import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { useEnv } from "./EnvContext";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import useGlobalToast from "../Utils/GlobalFunctions/toast";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const toast = useGlobalToast();
  const navigate = useNavigate();

  const { backendUrl, key } = useEnv();
  const [setError] = useState(null);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLogingIn, setIsLogingIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [generatedOtp, setGeneratedOtp] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false); // Modal visibility state

  const updateUserData = (isUpdateLocalData = false, updatedUserData) => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      try {
        const bytes = CryptoJS.AES.decrypt(storedUserData, key);
        const decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        // Check if the data has expired
        if (Date.now() > decryptedUserData.expiry) {
          localStorage.removeItem("userData");
          toast.error("Session expired! Please log in again.", "");
          navigate("/login"); // Redirect to login page
          return;
        }

        if (isUpdateLocalData) {
          decryptedUserData.dp = updatedUserData?.dp;
          decryptedUserData.empId = updatedUserData?.empId;
          decryptedUserData.name = updatedUserData?.name;
          decryptedUserData.userType = updatedUserData?.userType;

          const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(decryptedUserData),
            key
          ).toString();
          localStorage.setItem("userData", encryptedData);
        }

        setUserData(decryptedUserData);
      } catch (err) {
        console.error("User Data Decrypting Error: ", err);
        toast.error("Failed to load user data!", "");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    updateUserData();
  }, []);

  // const login = async (email, password, rememberMe) => {
  //     const loggedInUser = { email, password, rememberMe };

  //     try {
  //         setIsLogingIn(true); // Set login loading true

  //         if (rememberMe) {
  //             const encryptedLoginData = CryptoJS.AES.encrypt(
  //                 JSON.stringify({ email, password }),
  //                 key
  //             ).toString();
  //             localStorage.setItem("loginCredential", encryptedLoginData);
  //         }
  //         setUser(loggedInUser);

  //         // Handle Login API
  //         const response = await axios.post(`${backendUrl}/user/login`, { email, password });
  //         toast.success("Login successful!", "");

  //         const data = response.data.data;
  //         const expiryTime = Date.now() +  60000; // 24 hours in milliseconds

  //         // Combine user data with expiry timestamp
  //         const userDataWithExpiry = {
  //             ...data,
  //             expiry: expiryTime,
  //         };

  //         // Encrypt user data
  //         const encryptedData = CryptoJS.AES.encrypt(
  //             JSON.stringify(userDataWithExpiry),
  //             key
  //         ).toString();

  //         localStorage.setItem("userData", encryptedData);
  //         setUserData(data);

  //         const role = data?.userType;
  //         console.log(data)

  //         switch (role) {
  //             case "Admin":
  //                 navigate("/admin_dashboard/Dashboard/dashboard");
  //                 break;
  //             case "HR Manager":
  //                 navigate("/hr_dashboard/Dashboard/dashboard");
  //                 break;
  //             case "Business Developer":
  //                 navigate("/bd_dashboard/JobDescriptions/Active");
  //                 break;
  //             case "Recruiter":
  //                 navigate("/recruiter_dashboard/JobDescriptions/Active");
  //                 break;
  //             default:
  //                 toast.error("Role not recognized!", "");
  //                 break;
  //         }
  //     } catch (err) {
  //         console.log("Error", err);
  //         let error = err.response
  //             ? err.response?.data?.message
  //             : err.message
  //             ? err.message
  //             : "Something Went Wrong";
  //         setError(error);
  //         toast.error("Error", error);
  //     } finally {
  //         setIsLogingIn(false); // Set login loading false
  //     }
  // };

  // Logout Function
  const logout = () => {
    setUser(null);
    setUserData(null);
    localStorage.removeItem("userData");
    navigate("/");
    toast.success("Logout Successful!", "");
    console.log("User logged out");
  };

  const sendOtp = async (email) => {
    try {
      const response = await axios.get(`${backendUrl}/user/2fa_gen_otp`, {
        params: { email}, // Query parameters
        headers: { "Content-Type": "application/json" }, // Optional headers
      });
      console.log("OTP API Response:", response.data);
      setGeneratedOtp(response.data.otp);
      return response.data;
    } catch (error) {
      console.error(
        "Error sending OTP:",
        error.response?.data || error.message
      );
      throw error;
    }
  };

  //save OTP in DB after successful verification
  const storeOtp = async(email, userType, otp) => {
    try{
      const response = await axios.post(`${backendUrl}/user/storeOtp` ,{email, userType, otp}, {
        headers: { "Content-Type": "application/json" }, // Optional headers
      });
      console.log(response);
      console.log('OTP Saved Successfully!!...');
    } catch(error){
      console.error("Error saving OTP:", 
      error.response?.data || error.message
      );
      throw error;
    }
  }

  // Reset the OTP after 10 minutes
  useEffect(() => {
    if (generatedOtp) {
      const timeout = setTimeout(() => {
        setGeneratedOtp(""); // Reset OTP
        console.log("OTP reset after 10 minutes");
      }, 10 * 60 * 1000); // 10 minutes in milliseconds

      // Cleanup the timeout if `generatedOtp` changes before 10 minutes
      return () => clearTimeout(timeout);
    }
  }, [generatedOtp]);

  //login function
  const login = async (email, password, rememberMe) => {
    const loggedInUser = { email, password, rememberMe };

    try {
      setIsLogingIn(true); // Set login loading true

      // Encrypt credentials and store in localStorage if 'rememberMe' is true
      if (rememberMe) {
        const encryptedLoginData = CryptoJS.AES.encrypt(
          JSON.stringify({ email, password }),
          key
        ).toString();
        localStorage.setItem("loginCredential", encryptedLoginData);
      }
      setUser(loggedInUser);

      // Handle Login API
      const response = await axios.post(`${backendUrl}/user/login`, {
        email,
        password,
      });
      //toast.success("Login successful!", "");

      // Extract user data
      const data = response.data.data;
      console.log(data)
      const hasOtp = data.hasOtp;

      
      const now = new Date(); // Get the current date and time
      const expiryTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999).getTime(); // Today's 11:59:59 PM
      // 24 hours in milliseconds

      // Combine user data with expiry timestamp
      const userDataWithExpiry = {
        ...data,
        expiry: expiryTime,
      };

      // Encrypt user data
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(userDataWithExpiry),
        key
      ).toString();

      localStorage.setItem("userData", encryptedData);
      setUserData(data);

      // Open OTP modal
      if (hasOtp == false) {
        // Show OTP modal
        await sendOtp(email); // Simulate sending OTP
        setShowOtpModal(true);
      } else {
        // Navigate directly to the dashboard
        toast.success("Login successful!", "Redirecting to dashboard...");

        const role = data?.userType; // Assuming `userData` contains user info

      switch (role) {
        case "Admin":
          navigate("/admin_dashboard/Dashboard/dashboard");
          break;
        case "HR Manager":
          navigate("/hr_dashboard/Dashboard/dashboard");
          break;
        case "Business Developer":
          navigate("/bd_dashboard/JobDescriptions/Active");
          break;
        case "Recruiter":
          navigate("/recruiter_dashboard/JobDescriptions/Active");
          break;
        default:
          toast.error("Role not recognized!");
          break;
       }
      }

    } catch (err) {
      console.log("Error", err);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error("Error", error);
    } finally {
      setIsLogingIn(false); // Set login loading false
    }
  };

  const verifyOtp = async (email,otp) => {
    if (otp === generatedOtp) {
      toast.success("OTP Verified!","Login successful!");

      setShowOtpModal(false); // Close OTP modal

      const userType = userData?.userType; // Assuming `userData` contains user info
      //call API to store otp in DB
      await storeOtp(email, userType, otp);

      switch (userType) {
        case "Admin":
          navigate("/admin_dashboard/Dashboard/dashboard");
          break;
        case "HR Manager":
          navigate("/hr_dashboard/Dashboard/dashboard");
          break;
        case "Business Developer":
          navigate("/bd_dashboard/JobDescriptions/Active");
          break;
        case "Recruiter":
          navigate("/recruiter_dashboard/JobDescriptions/Active");
          break;
        default:
          toast.error("Role not recognized!");
          break;
      }
    } else {
      toast.error("Invalid OTP!");
    }
  };


  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        isLogingIn,
        logout,
        setUserData,
        userData,
        loading,
        updateUserData,
        sendOtp,
        verifyOtp,
        showOtpModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
