// import { useEffect } from "react";
// import "./App.css";
// import AllRoutes from "./Routes/AllRoutes";
// import { useNavigate } from "react-router-dom";
// import CryptoJS from "crypto-js";
// import { useAuth } from "./context/AuthContext";
// import { useEnv } from "./context/EnvContext";
// import useGlobalToast from "./Utils/GlobalFunctions/toast";

// function App() {

//     const toast = useGlobalToast();
//     const navigate = useNavigate();
//     const {setUser, setUserData} = useAuth()
//     const {  key } = useEnv();


//     localStorage.removeItem("chakra-ui-color-mode");
//     useEffect(() => {
//         const interval = setInterval(() => {
//             const storedUserData = localStorage.getItem("userData");
//             if (storedUserData) {
//                 try {
//                     const bytes = CryptoJS.AES.decrypt(storedUserData, key);
//                     const decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
//                     if (Date.now() > decryptedUserData.expiry) {
//                         // Clear session and navigate to login
//                         localStorage.removeItem("userData");
//                         setUser(null);
//                         setUserData(null);
//                         toast.error("Session expired! Please log in again.", "");
//                         navigate("/login");
//                     }
//                 } catch (err) {
//                     console.error("Error in session checker:", err);
//                 }
//             }
//         }, 1000); // Check every 15 min
    
//         return () => clearInterval(interval); // Cleanup interval
//     }, [key, navigate]);

//     return (
//         <div className="App">
//             <AllRoutes />
//         </div>
//     );
// }

// export default App;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useAuth } from "./context/AuthContext";
import { useEnv } from "./context/EnvContext";
import useGlobalToast from "./Utils/GlobalFunctions/toast";
import AllRoutes from "./Routes/AllRoutes";

function App() {
    const toast = useGlobalToast();
    const navigate = useNavigate();
    const { setUser, setUserData } = useAuth();
    const { key } = useEnv();

    localStorage.removeItem("chakra-ui-color-mode");

    const [lastActivityTime, setLastActivityTime] = useState(Date.now());
    const [hasLoggedOut, setHasLoggedOut] = useState(false); // Track logout state

    useEffect(() => {
        const handleUserActivity = () => {
            setLastActivityTime(Date.now());
            if (hasLoggedOut) {
                setHasLoggedOut(false); // Reset logout flag when user interacts
            }
        };

        // Function to handle session expiry
        const checkSessionExpiry = () => {
            const storedUserData = localStorage.getItem("userData");
            if (storedUserData) {
                try {
                    const bytes = CryptoJS.AES.decrypt(storedUserData, key);
                    const decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                    if (Date.now() > decryptedUserData.expiry) {
                        clearSession();
                        toast.error("Session expired! Please log in again.");
                    }
                } catch (err) {
                    console.error("Error in session checker:", err);
                }
            }
        };

        // Function to handle user inactivity
        const handleInactivity = () => {
            const currentTime = Date.now();
            const inactivityLimit = 30 * 60 * 1000; // 30 minute in milliseconds
            if (currentTime - lastActivityTime > inactivityLimit && !hasLoggedOut) {
                setHasLoggedOut(true); // Set flag to prevent multiple toasts
                clearSession();
                toast.warning("You have been logged out due to inactivity.");
            }
        };

        const clearSession = () => {
            localStorage.removeItem("userData");
            localStorage.removeItem("loginCredential");
            setUser(null);
            setUserData(null);
            navigate("/login");
        };

        // Check session expiry 15 min.
        const sessionInterval = setInterval(checkSessionExpiry, 15 * 60 * 1000);

        // Check inactivity every 5 min.
        const inactivityInterval = setInterval(handleInactivity, 5 * 60 * 1000);

        // Track user activity to reset inactivity timer
        const activityEvents = ['mousemove', 'keydown', 'click'];
        activityEvents.forEach(event => window.addEventListener(event, handleUserActivity));

        // Cleanup intervals and event listeners on component unmount
        return () => {
            clearInterval(sessionInterval);
            clearInterval(inactivityInterval);
            activityEvents.forEach(event => window.removeEventListener(event, handleUserActivity));
        };
    }, [key, navigate, lastActivityTime, setUser, setUserData, hasLoggedOut]);

    return (
        <div className="App">
            <AllRoutes />
        </div>
    );
}

export default App;
