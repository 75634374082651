import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Icon,
} from "@chakra-ui/react";
import { ImStatsBars } from "react-icons/im";

import CandidateHeader from "./CandidateHeader";
import CandidateBody from "./CandidateBody";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useCandidate } from "../../../context/CandidateContext";

const CandidateContainer = () => {
  const {
    loading,
    fetchAllCandidates,
    allCandidates,
    hasMoreCandidates,
    candidatesPageNo,
    filteredCandidates,
    setFilteredCandidates,
    hasMoreFilteredCandidates,
    filteredCandidatesPageNo,
    fetchFilteredCandidates
  } = useCandidate();

  const { status } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const initialPath = pathname.split("/")[1];

  const [tabIndex, setTabIndex] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [showDashCandidateTab, setShowDashCandidateTab] = useState(false);

  const tabConfig = [
    { status: "All", key: "all", color: "" },
    { status: "Selected", key: "selected", color: "green" },
    { status: "Processing", key: "processing", color: "blue" },
    { status: "OnBench", key: "onBench", color: "orange" },
  ];

  useEffect(() => {
    const currentTab = tabConfig.find((tab) => tab.status === status) || tabConfig[0];
    fetchAllCandidates(currentTab.key, 1, 30);
    setTabIndex(tabConfig.indexOf(currentTab));

    if (status === "dashboardData") {
      setShowDashCandidateTab(true);
    } else {
      setShowDashCandidateTab(false);
    }
  }, [status]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    setFilteredCandidates({ data: [] }); // Clear the filtered candidates
    const tabMapping = ["All", "Selected", "Processing", "OnBench"];
    navigate(`/${initialPath}/Candidates/${tabMapping[index]}`);
  };
// Determine the appropriate fetch function
const fetchCandidates = filteredCandidates?.length > 0
  ? fetchFilteredCandidates
  : fetchAllCandidates;

  // Update useEffect for resetting sortedData
  useEffect(() => {
    if (filteredCandidates?.length > 0) {
      // Use filtered data if available
      setSortedData(filteredCandidates);
    } else {
      // Use default data for the active tab
      setSortedData(allCandidates[tabConfig[tabIndex]?.key] || []);
    }
  }, [filteredCandidates, allCandidates, tabIndex]);

  return (
    <Tabs
      isLazy
      variant="enclosed"
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <CandidateHeader initialPath={initialPath} />
      <TabList>
        {tabConfig.map((tab) => (
          <Tab key={tab.key}>
            <Text
              bg={tagColor(tab.color).bg}
              color={tagColor(tab.color).clr}
              px={2}
              borderRadius={20}
              fontWeight="bold"
              fontSize="sm"
            >
              {tab.status}
            </Text>
          </Tab>
        ))}
        {showDashCandidateTab && (
          <Tab>
            <Icon as={ImStatsBars} fontSize="24px" />
          </Tab>
        )}
      </TabList>

      <TabPanels>
        {tabConfig.map((tab) => (
          <TabPanel key={tab.key}>
            <CandidateBody
              data={sortedData} // Updated to use sortedData
              fetchAllCandidates={fetchCandidates} // Use the determined fetch function
              hasMore={filteredCandidates?.length > 0
                ? hasMoreFilteredCandidates
                : hasMoreCandidates[tab.key]
              } // Use appropriate hasMore flag
              pageNo={filteredCandidates?.length > 0
                ? filteredCandidatesPageNo
                : candidatesPageNo[tab.key]
              } // Use appropriate page number
              status={tab.key}
              loading={loading}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default CandidateContainer;
