import axios from "axios";
import React, { createContext, useState, useContext, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import useGlobalToast from "../Utils/GlobalFunctions/toast";
import {
  getStatusCtoS,
  getStatusStoC,
} from "../Utils/GlobalFunctions/GetStatus";

const CandidateContext = createContext();

export const CandidateContextProvider = ({ children }) => {
  const toast = useGlobalToast();
  const { backendUrl } = useEnv();
  const { userData } = useAuth();

  const [candidateData, setCandidateData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCandidate, setLoadingCandidate] = useState(false);
  const [error, setError] = useState(null);

  // handle matching candidates
  const [allMatchingCandidates, setAllMatchingCandidates] = useState({
    all: [],
    selected: [],
    processing: [],
    bench: [],
  });
  const [hasMoreMatchingCandidates, setHasMoreMatchingCandidates] = useState({
    all: true,
    selected: true,
    processing: true,
    bench: true,
  });
  const [matchingCandidatesPageNo, setMatchingCandidatesPageNo] = useState({
    all: 1,
    selected: 1,
    processing: 1,
    bench: 1,
  });

  // handle candidates
  const [allCandidates, setAllCandidates] = useState({
    all: [],
    selected: [],
    processing: [],
    onBench: [],
  });
  const [searchedCandidates, setSearchedCandidates] = useState({
    all: [],
    selected: [],
    processing: [],
    onBench: [],
  });
  const [hasMoreCandidates, setHasMoreCandidates] = useState({
    all: true,
    selected: true,
    processing: true,
    onBench: true,
  });
  const [candidatesPageNo, setCandidatesPageNo] = useState({
    all: 1,
    selected: 1,
    processing: 1,
    onBench: 1,
  });
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [filterItem,setFilterItem] = useState([])
  const [hasMoreFilteredCandidates, setHasMoreFilteredCandidates] =
    useState(true);
  const [filteredCandidatesPageNo, setFilteredCandidatesPageNo] = useState(1);

  const [candidateAutoFillData, setCandidateAutoFillData] = useState({
    name: "",
    email: "",
    phone: "",
    skills: [],
  });

  const memoizedCandidates = useMemo(() => allCandidates, [allCandidates]);
  const memoizedFilteredCandidates = useMemo(() => filteredCandidates, [filteredCandidates]);

  // Fetch All Matching Candidates
  const fetchMatchingCandidates = async (
    status,
    page = 1,
    limit = 30,
    updateData = false,
    jobDescriptionId
  ) => {
    setLoading(true);
    setError(null);
    try {
      let cs;
      switch (status) {
        case "all":
          status = "all";
          cs = "";
          break;
        case "selected":
          status = "selected";
          cs = "Selected";
          break;
        case "processing":
          status = "processing";
          cs = "Processing";
          break;
        case "bench":
          status = "bench";
          cs = "On Bench";
          break;
        default:
          status = "all";
          cs = "all";
          break;
      }

      const token = userData?.token;
      const response = await axios.get(
        `${backendUrl}/candidate/all-percentage?jobDescriptionId=${jobDescriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            status: cs,
            page,
            limit,
          },
        }
      );

      const fetchedMatchingCandidates = response.data;
      // console.log("fetchedMatchingCandidates", fetchedMatchingCandidates);

      if (updateData) {
        setAllMatchingCandidates((prevState) => ({
          ...prevState,
          [status]: [...prevState[status], ...fetchedMatchingCandidates],
        }));

        setMatchingCandidatesPageNo((prevState) => ({
          ...prevState,
          [status]: prevState[status] + 1,
        }));

        if (fetchedMatchingCandidates.length < limit) {
          setHasMoreMatchingCandidates((prevState) => ({
            ...prevState,
            [status]: false,
          }));
        }
      } else {
        setAllMatchingCandidates((prevState) => ({
          ...prevState,
          [status]: fetchedMatchingCandidates,
        }));

        setMatchingCandidatesPageNo((prevState) => ({
          ...prevState,
          [status]: 2,
        }));

        setHasMoreMatchingCandidates((prevState) => ({
          ...prevState,
          [status]: fetchedMatchingCandidates.length === limit,
        }));
      }

      toast.success(`${status} Candidates fetched successfully!`);
    } catch (err) {
      console.log("Error", err);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch All Candidates
  const fetchAllCandidates = async (
    status,
    page,
    limit = 30,
    updateData = false
  ) => {
    setLoading(true);
    setError(null);

    // console.log(status,page,limit)
    try {
      if (!updateData && memoizedCandidates[status].length > 0) {
        setLoading(false);
        return;
      }
      let requestStatus = getStatusStoC(status);
      const token = userData?.token;
      const response = await axios.get(`${backendUrl}/candidate/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          status: requestStatus,
          page,
          limit,
        },
      });

      const fetchedCandidates = response.data;
      // console.log("fetchedCandidates", fetchedCandidates);

      // console.log(allCandidates)
      if (updateData) {
        setAllCandidates((prevState) => ({
          ...prevState,
          [status]: [...prevState[status], ...fetchedCandidates],
        }));

        setCandidatesPageNo((prevState) => ({
          ...prevState,
          [status]: prevState[status] + 1,
        }));

        if (fetchedCandidates.length < limit) {
          setHasMoreCandidates((prevState) => ({
            ...prevState,
            [status]: false,
          }));
        }
      } else {
        setAllCandidates((prevState) => ({
          ...prevState,
          [status]: fetchedCandidates, // Append fetched candidates to the correct array
        }));

        setCandidatesPageNo((prevState) => ({
          ...prevState,
          [status]: 2,
        }));

        setHasMoreCandidates((prevState) => ({
          ...prevState,
          [status]: fetchedCandidates.length === limit,
        }));
      }
      toast.success(`Candidates fetched successfully!`);
    } catch (err) {
      console.log("Error", err);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Specific Candidates
  const fetchSpecificCandidate = async (candidateId) => {
    // console.log("fetching candidate ", candidateId);
    setLoadingCandidate(true);
    setError(null);
    try {
      const token = userData?.token;
      const response = await axios.get(`${backendUrl}/candidate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          candidateId,
        },
      });

      const fetchedCandidate = response.data;
      // console.log("fetchedCandidate", fetchedCandidate);
      setCandidateData(fetchedCandidate);

      // toast.success(`Candidate fetched successfully!`);
    } catch (err) {
      console.log("Error", err);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error("Error", error);
    } finally {
      setLoadingCandidate(false);
    }
  };

  const addMultipleCandidate = async (excelCandidate) => {
    setLoading(true);
    try {
      const token = userData?.token;
      const response = await axios.post(
        `${backendUrl}/candidate/addmultiple`,
        { data: excelCandidate },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("New Candidate Added Successfully!");
    } catch (err) {
      console.log("Error", err.response);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  // Add Candidate
  const addCandidate = async (candidate) => {
    setLoading(true);
    setError(null);

    const newCandidate = new FormData();
    Object.keys(candidate).forEach((key) => {
      if (key === "workDetails" || key === "address" || key === "skills") {
        newCandidate.append(key, JSON.stringify(candidate[key]));
      } else {
        newCandidate.append(key, candidate[key]);
      }
    });
    try {
      const token = userData?.token;
      const response = await axios.post(
        `${backendUrl}/candidate/add`,
        newCandidate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("add candidate response", response);

      const newCand = response.data.candidate;
      const status = getStatusCtoS(candidate.currentStatus);

      setAllCandidates((prevState) => ({
        ...prevState,
        [status]: [...prevState[status], newCand],
        all: [...prevState.all, newCand],
      }));

      toast.success("New Candidate Added Successfully!");
    } catch (err) {
      console.log("Error", err.response);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  // Update Candidate
  const updateCandidate = async (
    candidate,
    candidateId,
    candidatePrevStatus
  ) => {
    setLoading(true);
    setError(null);
    const UpdatedCandidate = new FormData();

    Object.keys(candidate).forEach((key) => {
      if (key === "workDetails" || key === "address" || key === "skills") {
        UpdatedCandidate.append(key, JSON.stringify(candidate[key]));
      } else {
        UpdatedCandidate.append(key, candidate[key]);
      }
    });
    try {
      const token = userData?.token;
      const response = await axios.put(
        `${backendUrl}/candidate/update?candidateId=${candidateId}`,
        UpdatedCandidate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Update candidate response", response);

      const updatedCandidateData = response.data.candidate;
      const currStatus = getStatusCtoS(candidate.currentStatus);
      const prevStatus = getStatusCtoS(candidatePrevStatus);

      setAllCandidates((prevCandidates) => {
        const updatedCandidates = { ...prevCandidates };

        // Update the 'all' array
        updatedCandidates.all = prevCandidates.all.map((candidate) =>
          candidate._id === candidateId ? updatedCandidateData : candidate
        );

        if (currStatus == prevStatus) {
          // Update the 'current status' array
          updatedCandidates[currStatus] = prevCandidates[currStatus].map(
            (candidate) =>
              candidate._id === candidateId ? updatedCandidateData : candidate
          );
        } else {
          // Remove from Previous Staus array
          updatedCandidates[prevStatus] = prevCandidates[prevStatus].filter(
            (candidate) => candidate._id !== candidateId
          );

          // Add to current Status array
          if (updatedCandidates[currStatus]?.length > 0)
            updatedCandidates[currStatus] = [
              updatedCandidateData,
              ...prevCandidates[currStatus],
            ];
        }

        return updatedCandidates;
      });

      toast.success("Candidate Updated Successfully!");
    } catch (err) {
      console.log("Error", err);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  // Delete Candidate
  const deleteCandidate = async (candidateId, candidatePreviousStatus) => {
    // Prompt the user for confirmation
    const confirmation = window.prompt(
      'Type "delete candidate" to confirm the deletion:'
    );

    if (confirmation !== "delete candidate") {
      toast.error("Deletion cancelled or incorrect phrase entered.");
      return;
    }

    setLoading(true);
    setError(null);

    // console.log("Candidtate Id", candidateId);

    try {
      const token = userData?.token;
      const response = await axios.delete(
        `${backendUrl}/candidate?candidateId=${candidateId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Delete candidate response", response);
      const status = getStatusCtoS(candidatePreviousStatus);

      setAllCandidates((prevCandidates) => {
        const updatedCandidates = { ...prevCandidates };

        // Update the 'all' array
        updatedCandidates.all = prevCandidates.all.filter(
          (c) => c._id !== candidateId
        );

        // Update the 'current status' array
        updatedCandidates[status] = prevCandidates[status].filter(
          (c) => c._id !== candidateId
        );
        return updatedCandidates;
      });

      toast.success("Candidate Deleted Successfully!");
    } catch (err) {
      console.log("Error", err);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  //AutoFill api handle
  const autoFill = async (candidataCv) => {
    setLoading(true);
    setError(null);
    try {
      const token = userData?.token;
      const response = await axios.post(
        `${backendUrl}/candidate/autofill`,
        candidataCv,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCandidateAutoFillData(response.data);
      // console.log(candidataCv)
      toast.success(" Candidate Data auto fetched Successfully!");
    } catch (err) {
      console.log("Error", err.response);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

    // Fetch filtered job descriptions
    const fetchFilteredCandidates = async (
      filters,
      page = 1,
      limit = 30,
      forceFetch = false,
  ) => {
      setLoading(true);
      setError(null);
      try {
          // If no forceFetch and filteredCandidates already exist, return existing data
          if ((!forceFetch && memoizedFilteredCandidates.length > 0 ) && filterItem.length == 0) {
              setLoading(false);
              return;
          }

          const token = userData?.token;
          const response = await axios.post(`${backendUrl}/candidate/filter`, 
              {
                  ...filters, // Send the filters array in the body
              },
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                  },
                  params:{
                      page,
                      limit,
                  }
              }
          );

          const fetchedCandidates = response.data;
          console.log("fetchedFilteredCandidates", fetchedCandidates);

          if (forceFetch) {
              // If it's a fresh fetch or forced fetch, replace filteredCandidates
              setFilteredCandidates((prev) => [...prev, ...fetchedCandidates.data]);
              setFilteredCandidatesPageNo(prev => prev+1);
              if (fetchedCandidates.data.length < limit) {
                setHasMoreFilteredCandidates(false);
              }
          } else {
              // Append to the existing filteredCandidates
              setFilteredCandidates(fetchedCandidates.data);
              setFilteredCandidatesPageNo((prevPage) => prevPage + 1);
              setHasMoreFilteredCandidates(fetchedCandidates.data.length === limit);
          }
          if(fetchedCandidates?.data?.length>0){
            toast.success("Filtered Candidates fetched successfully!");
          }else{
            toast.error("No data found!");
          } 
      } catch (err) {
          console.log("Error", err);
          let error = err.response?.data?.message || err.message || "Something Went Wrong";
          setError(error);
          toast.error("Error", error);
      } finally {
          setLoading(false);
      }
  };

  return (
    <CandidateContext.Provider
      value={{
        loading,
        setLoading,
        loadingCandidate,
        candidateData,
        setCandidateData,
        fetchSpecificCandidate,
        fetchAllCandidates,
        allCandidates,
        setAllCandidates,
        addCandidate,
        updateCandidate,
        deleteCandidate,
        fetchMatchingCandidates,
        allMatchingCandidates,
        hasMoreMatchingCandidates,
        matchingCandidatesPageNo,
        setAllMatchingCandidates,
        hasMoreCandidates,
        candidatesPageNo,
        searchedCandidates,
        setSearchedCandidates,
        addMultipleCandidate,
        autoFill, // Add the autoFill function
        candidateAutoFillData,
        setCandidateAutoFillData,
        filteredCandidates,
        setFilteredCandidates,
        hasMoreFilteredCandidates,
        setHasMoreFilteredCandidates,
        filteredCandidatesPageNo,
        setFilteredCandidatesPageNo,
        fetchFilteredCandidates,
        filterItem,
        setFilterItem
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};

export default CandidateContext;

export const useCandidate = () => useContext(CandidateContext);
