import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  InputGroup,
  InputRightElement,
  IconButton,
  Checkbox,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useAuth } from "../context/AuthContext";
import { useEnv } from "../context/EnvContext";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import useGlobalToast from "../Utils/GlobalFunctions/toast";

const OTPInput = ({ otp, setOtp }) => {
  if (!Array.isArray(otp)) {
    console.error("Error: otp must be an array.");
    return null; // Return nothing if `otp` is invalid
  }

  const handleChange = (value, index) => {
    if (!/^\d*$/.test(value)) return; // Allow only numbers
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input if a digit is entered
    if (value && index < 3) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <HStack spacing={2} justify="center">
      {otp.map((value, index) => (
        <Input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          value={value}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength={1}
          textAlign="center"
          fontSize="lg"
          width="3rem"
          height="3rem"
          border="2px solid"
          borderColor="gray.300"
          focusBorderColor="blue.500"
        />
      ))}
    </HStack>
  );
};

const LoginForm = () => {
  const navigate = useNavigate();
  const toast = useGlobalToast();
  const { key } = useEnv();
  const { login, sendOtp, verifyOtp, showOtpModal } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [timer, setTimer] = useState(15 * 60); // 15 minutes in seconds

  useEffect(() => {
    let countdown;

    if (showOtpModal) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            toast.error(
              "OTP has expired!..",
              "Click on Login to get OTP again"
            ); // Show a toast notification
            setOtp(""); // Reset the OTP input
            onClose(); // Close the modal
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000); // Decrement every second
    }

    // Cleanup on unmount or when modal is closed
    return () => clearInterval(countdown);
  }, [showOtpModal, onClose]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoggingIn(true);

    try {
      await login(email, password, rememberMe); // Simulate login API call

      if (showOtpModal) {
        toast.success(
          "Login successful!",
          "An OTP has been sent to your email."
        );
      }

      onOpen(); // Open OTP modal
    } catch (error) {
      toast.error("Error!", "Invalid email or password. Please try again.");
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleVerifyOtp = async () => {
    setIsVerifying(true);
    setTimeout(async () => {
      try {
        const otpValue = otp.join(""); // Combine OTP array into a single string
        const verified = await verifyOtp(email, otpValue); // Simulate OTP verification
        if (verified) {
          setIsOtpVerified(true);
          toast.success("Success!", "OTP verified successfully.");

          onClose(); // Close OTP modal

          // Navigate based on role (example logic)
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error!", "Failed to verify OTP. Please try again.");
      } finally {
        setIsVerifying(false);
      }
    }, 1000); // Delay of 1.5 seconds
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <Text className=" mb-2 text-white font-bold text-2xl">Login Form</Text>
      <Box
        bg="#2D3748"
        p={6}
        rounded="md"
        width="100%"
        maxWidth="md"
        boxShadow="xl"
      >
        <form onSubmit={handleLogin}>
          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl id="password" mt={4} isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                  variant="ghost"
                  aria-label={showPassword ? "Hide password" : "Show password"}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  onClick={togglePasswordVisibility}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl display="flex" alignItems="center" mt={4}>
            <Checkbox
              id="rememberMe"
              isChecked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            >
              Remember Me
            </Checkbox>
          </FormControl>
          <Button
            type="submit"
            colorScheme="teal"
            width="full"
            mt={4}
            isLoading={isLoggingIn}
          >
            Login
          </Button>
        </form>
      </Box>

      {/* OTP Modal */}
      {showOtpModal && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign="center">Verify OTP</ModalHeader>
            <ModalBody>
              <OTPInput otp={otp} setOtp={setOtp} />
              <Text mt={4} color="red.500" textAlign="center">
                OTP will expire in {Math.max(0, Math.floor(timer / 60))}:
                {String(timer % 60).padStart(2, "0")} minutes.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="teal"
                mr={3}
                onClick={handleVerifyOtp}
                isLoading={isVerifying}
              >
                Verify
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default LoginForm;
