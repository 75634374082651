import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tag,
  TagLabel,
  Box,
  Avatar,
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Badge,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";

import { formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import { useEnv } from "../../../context/EnvContext";
import ClientJds from "./ClientJds";
import ClientApplications from "./ClientApplications";
import { useClient } from "../../../context/ClientContext";
import JDStatus from "../DashboardTools/JDStatus";
import JDList from "../DashboardTools/JDList";
import { useDashboard } from "../../../context/DashboardContext";
import ApplicationOnProcess from "./ApplicationOnProcess";

const ClientDetails = ({ isOpen, onClose, client }) => {
  const { backendUrl } = useEnv();
  const [activeTab, setActiveTab] = useState(0); // Track active tab
  const { fetchClientApplications, clientAppStatistics } = useClient();
  const { clientJDData, filterClientJds } = useDashboard();

  useEffect(() => {
    filterClientJds(client?._id);
    fetchClientApplications(client?._id);
  }, []);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <Modal isLazy isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent bg="#1C2E47" className="jd-details">
          <ModalHeader>
            <Flex gap={2} align="center">
              {client?.clientId} -{" "}
              <Avatar
                size="xs"
                name={client?.name}
                src={`${backendUrl}${client?.logo}`}
              />
              {client?.name}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ paddingBottom: "20px" }}>
            <Tabs
              isFitted
              variant="enclosed"
              onChange={handleTabChange} // Update active tab
            >
              <TabList mb="1em">
                <Tab>Client Details</Tab>
                <Tab>JDs</Tab>
                <Tab>Applications</Tab>
              </TabList>
              <TabPanels>
                {/* Client Details Tab */}
                <TabPanel>
                  <Flex>
                    {/* Left Side: Sticky Content */}
                    <Box
                      width="40%"
                      style={{ whiteSpace: "normal" }}
                      position="sticky"
                      top={0}
                      alignSelf="flex-start"
                      bg="transparent"
                      p={4}
                      boxShadow="md"
                      height="100vh"
                      overflowY="auto"
                    >
                      <VStack align="start" spacing={4}>
                        <Box>
                          <b>Contact Number:</b> {client?.contactNo}
                        </Box>
                        <Box>
                          <b>Email:</b> {client?.email}
                        </Box>
                        <Box>
                          <b>Number of Employees:</b> {client?.noOfEmp}
                        </Box>
                        <Box>
                          <b>Established:</b> {client?.estd}
                        </Box>
                        <Box>
                          <b>Details:</b> {client?.details}
                        </Box>
                        <Box>
                          <b>Address:</b> {client?.address}, {client?.city},{" "}
                          {client?.state}, {client?.country} - {client?.pincode}
                        </Box>
                        <Box>
                          <b>Status:</b>{" "}
                          {client?.status === "Active" && (
                            <Badge variant="subtle" colorScheme="green">
                              Active
                            </Badge>
                          )}
                          {client?.status === "Inactive" && (
                            <Badge variant="subtle" colorScheme="orange">
                              Inactive
                            </Badge>
                          )}
                        </Box>
                        <Box>
                          <b>Primary Contacts:</b>
                          <Wrap mt={2}>
                            {client?.contacts?.map((contact, index) => (
                              <Tag
                                key={index}
                                size="lg"
                                borderRadius="full"
                                mr={1}
                                mb={1}
                              >
                                <TagLabel fontSize={"sm"}>
                                  {contact?.name} ({contact?.role}) -{" "}
                                  {contact?.contactNo} | {contact?.email}
                                </TagLabel>
                              </Tag>
                            ))}
                          </Wrap>
                        </Box>
                        <Box>
                          <b>Added On:</b> {formatDateTime(client?.createdAt)}
                        </Box>
                      </VStack>
                    </Box>

                    {/* Right Side: Scrollable Content */}
                    <Box
                      width="60%"
                      px={10}
                      overflowY="auto"
                      height="100vh"
                      bg="transparent"
                      p={4}
                      borderLeft="1px solid #e0e0e0"
                    >
                      {/* Job Description List Section */}
                      {clientJDData.jobDescriptionsInPriorityOrder && (
                        <Box
                          mb={8}
                          p={5}
                          bg="transparent"
                        >
                          <JDList dashJDData={clientJDData} />
                        </Box>
                      )}

                      {/* Application On Process Section */}
                      {clientAppStatistics && (
                        <Box px={5} bg="transparent">
                          <ApplicationOnProcess
                            applicationsOnProcessByStatusCount={
                              clientAppStatistics
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  </Flex>
                </TabPanel>

                {/* JDs Tab */}
                <TabPanel>
                  <Text
                    mb="20px"
                    fontSize="2xl"
                    align="center"
                    fontWeight="bold"
                  >
                    Job Descriptions (JDs)
                  </Text>
                  {activeTab === 1 && <ClientJds ClientId={client?._id} />}
                </TabPanel>

                {/* Applications Tab */}
                <TabPanel>
                  <Text
                    mb="20px"
                    fontSize="2xl"
                    align="center"
                    fontWeight="bold"
                  >
                    Applications
                  </Text>
                  {activeTab === 2 && (
                    <ClientApplications ClientId={client?._id} />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ClientDetails;
